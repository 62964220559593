.body {
    background-color: var(--bg-page-color);
    opacity: 1;
}
.subscriptionsHeaderDiv{
    width: 100%;
    height: 10%;
    text-align: center;
    font-size: 30px;
}
.subscriptionsMainDiv{
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.subscriptionsList{
    background-color: var(--bg-page-color);
    width: 30vw;
}
.subscriptionCard{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 25px;
    margin-bottom: 35px;
}
.subscriptionName{
    text-align: center;
    font-family: Nunito Bold;
}
.handleSubscriptionButton{
    color: #0b1215;
    /* text-transform: none; */
    font-family: Nunito Bold;
    --background:rgb(255, 200, 62);
    align-self: flex-end;
}

@media only screen and (max-width: 480px) {
    .subscriptionsMainDiv{
        overflow-y: auto;
    }
    .subscriptionsList{
        width: 90vw;
    }
    .handleSubscriptionButton{
        align-self: center;
    }

}
@media only screen and (min-width:480px) and (max-width: 900px) {
    .subscriptionsList{
        width: 90vw;
    }
}