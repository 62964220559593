.invitationContainer{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.invitationDiv{
    font-size: 20px;
    background-color: var(--bg-content-color);
    width: 50vw;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    border:2px solid var(--accents-color);
    border-radius: 20px;
    padding: 10px;
}
.invitationReasyLogo{
    content: url('../../../assets/icons//reasy_full_logo.svg');
    width: 100%;
    height: 20%;
}
.invitationGreetingPar{
    height: 10%;
    font-size: 2em;
    margin: 0;
}
.invitationContentDiv{
    height: 40%;
}
.invitationEmoji{
    font-size: 110px;
    margin: 0;
    text-align: center;
    text-shadow: 5px 5px 10px#cecece;
}
.invitationBottomDiv{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 30%;
    margin-top: 50px;
}
.invitationCtaPar{
    height: 10%;
    margin-bottom: 5%;
    margin-top: 0;
}
.invitationButtons{
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    
}
.invitationBtn{
    width: 40%;
    height: 70%;
    font-family: Nunito Bold;
    font-size: 1em;
    --background-focused:none;
    --background-hover:none;
    box-shadow: 10px 10px 10px 5px #cecece;
    border-radius: 15px;
    color: #000;
}
.declineBtn{
    --background:#ffa0a0;
}
.acceptBtn{
    --background:#a0ffb8;
}
.invitationLoginParagraph{
    height: 10%;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
}

@media (prefers-color-scheme: dark) {
    .invitationReasyLogo{
        content:url('../../../assets/icons/reasy_full_logo_darkmode.svg')
    }
    .invitationBtn{
        box-shadow: 10px 10px 10px 5px #1b1c35;
    }
    .invitationEmoji{
        text-shadow: 5px 5px 10px#1b1c35;
    
    }
}