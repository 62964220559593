@import url("../../../assets/colorsCSS/colorPalettes.css");

.credentialsSignupInputDiv {
    display: flex;
    width: 75%;
    flex-direction: column;
}

.credentialsSignupInputField {
    background: #f8f4fc;
    text-align: center;
    overflow: hidden;
    border: 1px solid #767676;
    border-radius: 50px;
}

.credentialsSignupButtonsDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding-left: 10px;
    padding-right: 10px; */
    align-items: center;
    width: 75%;
}

.credentialsSignupToggle {
    margin-right: 10px;
    width: 50px;
    --handle-background-checked: #3626A7;
}

@media (prefers-color-scheme: dark) {
    .credentialsSignupInputField {
        --background: var(--fileView-btn);
        --placeholder-color: #000000;
        --placeholder-opacity: .8;
        --color: #000000;
    }

    .credentialsSignupToggle {
        --background: #646464;

    }
}