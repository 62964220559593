@media (prefers-color-scheme: dark) {
	/* .Button {
		--color: white;
	}

	.Button>ion-icon {
		color: white;
	} */


}

@media (prefers-color-scheme: light) {
	:root {
		color: black !important;
		--colorIcon: 0;
		--background-Btn: black;
	}
}
.undoBtn,.redoBtn{
--background:transparent;
--box-shadow: none;
--padding-top:0;
--padding-bottom:0;
--padding-end:0;
--padding-start:0
}
.undoBtnIcon, .redoBtnIcon{
	color:var(--txt-color)
}

.resolution .popover-viewport {
	display: contents;
}

#mapsToolbarSave {
	--background: var(--bg-content-color);
}

.mapsEdgeTypeMainDiv {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.mapsEdgeTypeItem {
	display: flex;
	width: 33vw;
	flex-direction: column;
	justify-self: center;
	align-items: center;
	color: var(--txt-color);
}

.mapsSideMenu {
	--width: 25vw;
	pointer-events: none;
}

.mapsSideMenu>* {
	pointer-events: all;
}

.mapsSideMenu::part(backdrop) {
	background-color: transparent;
}

.mobileIcon {
	width: 25px;
	height: 25px;
}

.mapsMenuTitle {
	padding: 5px;
	text-align: center;
	font-size: 22px;
	font-weight: bold;
}

.dndflow {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
}

.reactflow-wrapper {
	background-color: var(--bg-content-color);
	width: 100%;
	height: 100%;
	text-transform: capitalize;
}

.react-flow__handle.connectable {
	opacity: 0;
	cursor: default;
	pointer-events: none;
}

.react-flow__handle {
	/* height: 10px;
	padding: 10px;
	margin: -10px; */
}

.react-flow__edge-path {
	stroke-width: 7px !important;
	stroke: grey;
	fill: transparent;
}

.react-flow__edge-textwrapper {
	stroke: black;
	fill: white;
}

.react-flow__edge:focus {
	outline: none;
}

.react-flow__edge:focus .react-flow__edge-path {
	stroke: black;
}

.mapEdgeLabelChangePopover {
	--width: 40vw;
	/* --height: fit-content; */
}

.mapEdgeLabelChangePopover .popover-viewport {
	display: contents;
}

@media only screen and (max-width: 520px) {
	.mapEdgeLabelChangePopover {
		--width: 80vw;
		/* --height: fit-content; */
	}

	.nodeNameChangePopover {
		/* --max-width: 50vw; */
		/* --min-width: 30%; */
		--width: 80vw !important;
		/* --height: max-content; */
	}

	/* .skip{
        position: absolute;
        left: 5px;
        bottom: 14%;
        padding: 0;
    }
    .skipAll{
        position: absolute;
        left: 5px;
        bottom: 25%;
        padding: 0;
    } */
}

.floatingedges .react-flow__handle {
	opacity: 0;
}

.mapEdgeTypePickerPopoverTitle {
	margin: 25px;
	font-size: 15px;
}

.mapEdgeLabelChangePopoverTitle {
	margin: 15px;
}

.mapEdgeLabelChangePopoverInput {
	padding: 15px;
}

.mapsImagePickerPopover {
	--width: 70vw;
	--height: 60vh;
	overflow-y: scroll;
}

.mapsImagePickerPopover .popover-viewport {
	display: contents;
}

.nodeConnectionText {
	position: absolute;
	color: rgb(36, 36, 36);
	text-align: center;
	font-size: 25px;
	top: 60px;
	background-color: rgb(255, 209, 124);
	height: 47px;
	z-index: 1000;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.fontSizePicker {
	border: 1px solid #333333;
	width: 35px;
	height: 20px;
	float: left;
	font-size: smaller;
}

.fontSizeRangeButtonsDiv {
	float: left;
	width: 12px;
	margin-right: 5px;
	margin-left: 2px;
	display: inline;
	margin-top: -7px;
	height: 18px;
}

.nodeConnectionText:after {
	content: " ";
	position: absolute;
	right: -49px;
	border-top: 25px solid transparent;
	border-right: none;
	border-left: 50px solid rgb(255, 209, 124);
	border-bottom: 25px solid transparent;
}

img {
	pointer-events: none;
}

@media only screen and (min-width: 320px) {
	.mapsImagePickerPopover {
		--width: 80% !important;
		--height: 60vh !important;
	}

	.imagePickerPopoverTitle {
		font-size: 15px;
	}
}

.floatingedges {
	cursor: pointer;
}

.react-flow__pane {
	cursor: default;
	/* top: -50% !important; */
	/* left: -42% !important; */
}

.fontModalMenu {
	width: 100%;
}

.react-flow__node {
	display: flex;
	align-items: center;
	height: min-content;
	font-weight: 700;
	position: absolute;
	text-align: center;
	padding: 10px;
	flex-direction: column;
	border: 1px solid;
	color: var(--txt-color) !important;
	cursor: grab;

}
.react-flow__node-default, .react-flow__node-input, .react-flow__node-output, .react-flow__node-group{
	min-width: 150px;
	width: auto;
}

@media print {
	body * {
		visibility: hidden;
	}

	.react-flow__viewport * {
		visibility: visible;
	}
}

.react-flow__node>img {
	max-width: 100px;
}

.react-flow__edges {
	overflow: visible !important;
}

.react-flow__viewport {
	width: 100%;
	height: 100%;
}

.addNodeButton {
	position: absolute;
	width: 15px;
	height: 15px;
	top: 50%;
	padding: 10px;
	left: 50%;
	/* left: 100%; */
	/* transform: translateY(-50%); */
}

.modalMenuList {
	width: 95%;
	height: 100%;
	background: transparent;
	overflow-y: scroll !important;
	padding-bottom: 36px;
}

.modalAutoLayoutDiv {
	display: flex;
	flex-direction: row;
	max-width: 100%;
}
.modalMenuAccordion{
	border-radius: 15px;
}
.modalMenuItem {
	width: 100%;
	font-size: 15px;
	--background: var(--bg-page-color);
	color: var(--txt-color);
}

.nodeNameChangePopover {
	/* --max-width: 50vw; */
	/* --min-width: 30%; */
	--width: 30vw;
	/* --height: max-content; */
}

.nodeNameChangePopover .popover-viewport {
	display: contents;
}

.nodeNameChangePopoverContent {
	display: flex;
	flex-direction: column;
	margin: 5px;
	padding: 1rem;
}

.nodeNameChangePopoverContent>ion-button {
	justify-content: center;
}

.nodeNameChangePopoverButton {
	max-width: 100%;
	align-self: center;
}

.sideButtons {
	transform: scaleY(0);
	transition: transform 400ms ease 0ms;
}

.bgPNG {
	background-color: var(--bg-page-color);
	left: 150;
}

.menuBtn::part(native) {
	background-color: transparent;
	box-shadow: none;
	padding: 0;
	cursor: pointer;
	width: 30px;
	color: var(--callToAction-color);
}

.menuBtm-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 0px;
	background: var(--bg-content-color);
	width: 100%;
}

input[type="color"] {
	display: flex;
	border: none;
	background-color: transparent;
	cursor: pointer;
	border-radius: 10px;
	width: 20px;
	height: 20px;
	align-self: center;
}

.colorpicker {
	padding: 0;
	border: none;
	border-radius: 10px;
	width: 20px;
	height: 20px;
	top: 100px;
}

.mapBackBtn {
	color: var(--txt-color);
}

.colorpicker::-webkit-color-swatch {
	border: none;
	border-radius: 10px;
	padding: 0;
}

.colorpicker::-webkit-color-swatch-wrapper {
	border: none;
	border-radius: 10px;
	padding: 0;
}

.menu-toggle-hidden {
	display: block;
	background-color: transparent;
}

.brushButton {
	background: url("../../../assets/icons/brush.svg") no-repeat;
	background-size: contain;
	width: 20px;
	height: 20px;
	color: ();
	/*filter: invert(1);*/
}

.Button {
	--background: transparent;
	--box-shadow: none;
	/* --color: var(var(--color))!important; */
	--ripple-color: blue;
	--margin-left: 10px;
	--color: var(--callToAction-color);
	--position: absolute;
	--background-opacity: 1;
}

#popoverContent .popover-content {
	/* position: absolute;
    top: 0px !important;
    left: auto !important;
    width: 229px;
    height: min-content;
    overflow: hidden;
    right: 0; */
	height: min-content;
	background: var(--bg-page-color);
}

.CloseButton {
	--background-hover: red;
	--background-opacity: 1;
	--position: absolute;
	--background: transparent;
	--box-shadow: none;
	--color: var(--background-Button);
}

.typeLink {
	--color: black;
}

.mapSideMenu {
	--padding-start: 15px;
	--padding-end: 15px;
}

.mapSideMenu::part(background) {
	background: var(--bg-content-color);
}

.colorButtonMaps::part(native) {
	background: transparent;
}

.colorButtonMaps ion-icon {
	color: var(--txt-color);
}

.connectionIcon {
	--background: transparent !important;
	--box-shadow: none;
	--border: none;
	--color: var(--txt-color);
}

.backButton {
	filter: invert(var(--colorIcon));
	--background: transparent;
	--box-shadow: none;
	--color: black;
}

.typeMaps {
	color: var(--txt-color);
}

.mindMapFontSizeDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.mapsEditingButton {
	--display: flex;
	--align-items: center;
	--box-shadow: none;
	--background: transparent;
}

.MapsToolbar {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}

.mapsSaveAndExit {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

#mapsModeEditingMobile {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.zoomInModeEditing {
	display: flex !important;
	flex-direction: center;
	justify-content: space-between;
	align-items: center;
}

.desktopHeaderButtonsDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	/* position: absolute; */
	padding-right: 30px;
	width: 100%;
	/* width: 25vw; */
	/* left: 37.5vw; */
	color: var(--txt-color);
}

.desktopHeaderButtonsDiv>ion-icon:hover {
	background: #e2e2e2;
	border-radius: 4px;
}

.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;

	top: 100%;
	left: 50%;
	margin-left: -60px;
	/* Use half of the width (120/2 = 60), to center the tooltip */

	/* Position the tooltip */
	position: absolute;
	z-index: 10000001;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

.fontSizeChangeDiv>ion-icon:hover {
	background: #e2e2e2;
	border-radius: 4px;
}

.headerSeparator {
	border-left: 1px solid var(--neutral-color);
	height: 20px;
	margin-left: 15px;
	margin-right: 15px;
}

.headerMapButtons {
	font-size: 26px;
}

.mapTitleSection {
	min-width: 30%;
	font-size: 16px;
	text-align: left;
	color: var(--txt-color);
}

.edgeTypeSelectorPopover {
	--width: 230px;
	--height: 170px;
}

.edgeTypeSelectorDiv {
	display: flex;
	width: 100%;
	height: 100px;
	flex-direction: row;
	justify-content: space-evenly;
}

.edgeTypeSelectorOption {
	display: flex;
	flex-direction: column;
	align-items: center;
	color:var(--txt-color)
}

.layoutOptionsPopover {
	--width: 230px;
	--height: 250px;

}

.layoutOption {
	width: 90%;
	display: flex;
	cursor: pointer;
	padding-left: 5%;
	padding-right: 5%;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	text-indent: 15px;
	margin-bottom: 5%;
	color:var(--txt-color);
}

.layoutOptionsHeader {
	font-size: larger;
	text-align: center;
	/* border-bottom: 1px solid #fff; */
	font-family: 'Nunito';
	color:var(--txt-color)
}

.layoutOptionsDiv {
	display: flex;
	width: 100%;
	height: 250px;
	flex-direction: column;
	align-items: center;
}

.layoutOptionsInnerDiv {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.layoutIcon {
	font-size: 50px;
}

.fontSizeChangeDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.fontSizeInput {
	max-width: 34px;
	border: 1px solid;
	border-color: #545454;
	border-radius: 4px;
	min-height: 24px !important;
	height: 30px;
	text-align: center;
	--highlight-color-focused: none;
	margin-left: 4px;
	margin-right: 4px;
}


.fontSizeInput.has-focus {
	border: 3px solid;
	border-color: var(--accents-color);
}

.fontChangeIcon {
	cursor: pointer;
	font-size: 24px;
	font-weight: 900;
}

.mapsMenu {
	display: flex;
	flex-direction: row;
	justify-items: flex-end;
	align-items: center;
}

.mapsMenuHeader {
	color: var(--txt-color);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
}

.divEyeMaps {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
}

.colorButtonMaps {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	color: var(--txt-color);
	font-size: 12px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 20px;
}

.mapSideMenuTxtContainer {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.mapSideMenuIcons {
	color: var(--txt-color);
}

.mapsToggle {
	--background-checked: var(--positive-color);
	--background: var(--neutral-color);

	width: auto;
}

.Button>ion-icon {
	color: var(--txt-color);
}

.Button::part(native) {
	color: var(--txt-color);
}

.settingsDownloadMaps {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
}

.settingLinkMaps {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
}

.settingTypeLinkMaps {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
}

.settingTextMaps {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
}

#mindMapModalMenu {
	height: 45vh;
	box-shadow: none;
	--width: 100%;
	position: fixed !important;
	top: auto;
	bottom: 0 !important;
	/* justify-content: end; */
	align-items: center;
	flex-direction: row;
	border-radius: 15px 15px 0 0;
}

#mindMapModalMenu>div {
	position: absolute;
	bottom: 0px;
	overflow-y: scroll;
}

#mindMapModalMenu .ion-page {
	justify-content: unset;
	background: var(--bg-page-color);
}

.modalMenuMapIcons {
	color: var(--txt-color);
}

.toolbarMindMapModalMenu {
	display: flex;
	align-items: center;
	color: var(--txt-color);
	/*justify-content: space-between;*/
}

.toolbarModalMap {
	--background: var(--bg-page-color);
}

.colorFillModalMenu {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.showImageModalMenu {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.testiModalMenu {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.fontSizeModalMenu {
	display: flex;
	/*justify-content: space-between;*/
	align-items: center;
}

.downloadMapsModalMenu {
	display: flex;
	/*justify-content: space-between;*/
	align-items: center;
	flex-direction: row;
}

.changeEdgeType {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
}

.mindMapsWeb {
	display: flex;
	flex-direction: column;
}

.mapsSideactionbtn {
	width: min-content;
	display: inline-flex;
}

.mapImgsPopup {
	color: var(--txt-color);
}

/* 
#actionsDiv:after,
#actionsDiv:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

#actionsDiv:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: var(--accents-color);
    border-width: 19px;
    left: 10%;
}

#actionsDiv:before {
    border-color: rgba(113, 158, 206, 0);
    border-bottom-color: var(--accents-color);
    border-width: 20px;
    left: 10%;
} */

#actionsDiv {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	border: 2px solid var(--accents-color);
	border-radius: 19px;
	background: var(--accents-color);
	color: var(--fileView-btn);
	width: fit-content !important;
	position: absolute;
	font-size: 18px;
}

@keyframes slideFromTop {
	from {
		opacity: 0;
		transform: translateY(-20%);
		user-select: none;
		z-index: -100000;
	}

	to {
		transform: translateY(0);
		user-select: all;
	}
}

@keyframes slideToTop {
	from {
		opacity: 1;
		transform: translateY(0);
	}

	to {
		transform: translateY(-20%);
		opacity: 0;
	}
}

#actionsDiv.visible {
	position: absolute;
	/* or 'relative' or 'fixed' based on parent */
	top: 0;
	left: 0;
	/* Initial state */
	transform: translateY(-20%);
	/* Animation settings */
	animation: slideFromTop 0.5s forwards;
	/* Adjust timing as needed */
}

#actionsDiv.hidden {
	position: absolute;
	/* or 'relative' or 'fixed' based on parent */
	top: 0;
	left: 0;
	/* Initial state */
	transform: translateY(0);
	user-select: none;
	pointer-events: none;

	/* Animation settings */
	animation: slideToTop 0.5s forwards;
	/* Adjust timing as needed */
}

.mapTtsButton {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	text-align: right;
	box-shadow: none;
	color: white;
	font-weight: bold;
	font-size: 20px;
	/* --background: var(--accents-color); */
}

.mapTtsIcon {
	color: white;
}

@media only screen and(max-width:1050px) {
	.react-flow__pane {
		top: -50% !important;
		left: -45% !important;
	}
}