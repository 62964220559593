.sessionDetailsPage{
    display: flex;
    align-items: center;
    justify-content: unset;

}
.sessionDetailsMainDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 22px;
}
.sessionDetailTitle{
    font-family: Nunito Bold;
    font-weight: bold;
    font-style: italic;
    font-size: clamp(1.5rem,2vw,2rem);
    /* margin-top: 2em; */
    max-width: 90vw;
    width: 90vw;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    margin-bottom: 20px;
    color: var(--txt-color);
}
.sessionsDetailsGoBackBtn{
    /* position: absolute; */
    margin-top: 1em; 
    font-size: clamp(.75rem, 2vw, 1.25rem);    
    --border-radius: 25px;
    --background: #3B82F6;
    /* min-height: 20px; */
    text-transform: none;

}
.sessionDetailRemoveButton{
    --border-radius: 25px;
    text-transform: none;
    font-family: Nunito;
    --background:rgb(193, 0, 0);
    margin-bottom: 1.5em;
    font-size: clamp(.75rem, 2vw, 1.25rem);
    height: 10px;
    cursor: pointer;
}
.fullGrid{
    grid-template-columns: repeat(3, 1fr) ;
}
.delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: #f00; /* Change color as needed */
    cursor: pointer;
    z-index: 1000000;
    display:none;
  }
  
.sessionDetailsResourcesGrid{
    width: 50vw;
    display: grid; 

    /* grid-template-rows: 30vh 30vh;
    grid-template-columns: 30vh 30vh; */
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;

    
    justify-content: center;
    justify-items: center;
    
    gap: 3vw;
    /* height: 80%; */
    
}

.flashcardsDescription{
    display: none;
    position: absolute;
    bottom: 0;
    max-height: 40%;
    height: auto;
}
.flashcardsDescription>p{
    font-size: .8rem;
    text-align: center;
    color: #535353;
}
.res_flashcards_collections:hover{
    padding-bottom: 20% !important;
}
.sessionDetailsResourceCard:hover{
    padding: 20px;
}
.sessionDetailsResourceCard:hover .flashcardsDescription{
    display: inline;
}
.fullGridCard:nth-child(4) {
    grid-row: 2;
    grid-column: 1 / 3;
}

.fullGridCard:nth-child(5) {
  grid-column: 2/4;
  grid-row: 2;
}

.sessionDetailsResourceCard{
    width: clamp(145px, 35vw, 200px);
    height: clamp(107px,20vh,170px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgb(105, 213, 252);
    border-radius: 25px;
    padding-bottom: 15px;
    cursor: pointer;
    transition: all .5s;
}
.sessionDetailsResourceType{
    text-align: center;
    font-size: clamp(1.4em,2vw,2em);
    font-family: Nunito Bold;
    font-weight: bold;
}

.sessionDetailsResourceIcon{
    width: 6vw;
    height: 6vw;
}

.argumentInputPopover{
    --width:50vw;
    --height:40vh;
}
.argumentInputPopover>div{
    width: 100%;
    height: 100%;
}
.argumentInputPopoverDiv{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--txt-color);
}
.delete-btns-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.deleteConfimrationPopoverDiv{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--txt-color);
}
.argumentInputPopoverTitle{
    font-size: 1.8em;
    font-family: Nunito Bold;
}
.deleteConfimrationPopoverTitle{
    font-size: 1.8em;
    font-family: Nunito Bold;
    text-align: center;
}
.deleteConfimrationPopoverContent{
    font-family: Nunito Bold;
    text-align: center;
}
.deleteConfimrationPopoverBtns{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.deleteConfimrationPopoverBtns>.argumentInputPopoverButton{
    margin: 0;
}
.argumentInputPopoverInput{
    width: 50%;
}
.argumentInputPopoverButton{
    margin-top: 35px;
    --background: rgb(0, 117, 0);
}
.deleteConfimrationPopoverButton{
    --background: rgb(220, 0, 0);
}
.argumentDismissBtn{
    --background: #929292c9;
}
.wobbleAnimation{
    -webkit-animation: removalWiggle .25s infinite both;
    animation: removalWiggle .25s infinite both;
}

@keyframes removalWiggle {
    0% {
        transform: rotate(1deg);
        animation-timing-function: ease-in;
    }
    50% {
        transform: rotate(-1.5deg);
        animation-timing-function: ease-out;
    }
}
@media (prefers-color-scheme: dark) {

    .sessionDetailsResourceType{
        color: black;
    }
    .sessionsDetailsGoBackBtn{
        --background: rgb(82, 82, 82);
    }
}

@media only screen and (max-width: 480px) {

    .sessionDetailsMainDiv{
        font-size: 18px;
    }
    .sessionDetailTitle{
        margin: 0;
    }
    .sessionsHomeOnboardingTitle{
        font-size: 2em;
        margin-top: 5vh;
        margin-bottom: 3vh;
    }
    .sessionDetailsResourcesGrid{
        width: 95vw;
        grid-template-rows: 30vh 30vh;
        grid-template-columns: 45vw 45vw;
    }
    .sessionDetailsResourceCard{
        /* width: 35vw; */
        /* height: 15vh; */
        height: clamp(107px,16vh,170px);
        margin: clamp(5px, 1vw, 20px);;
    }

    .sessionDetailsResourceIcon{
        width: 13vw;
        height: 13vw;
    }
    .fullGrid{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px ;
        grid-row-gap: 0px ;
    }
    .fullGridCard:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
    .fullGridCard:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
    .fullGridCard:nth-child(3) { grid-area: 2 / 1 / 3 / 2; }
    .fullGridCard:nth-child(4) { grid-area: 2 / 2 / 3 / 3; }
    .fullGridCard:nth-child(5) { grid-area: 3 / 1 / 4 / 3; }  


    .argumentInputPopover{
        --width:80vw;
        --height:40vh;
    }
    .argumentInputPopoverTitle{
        font-size: 1.4em;
    }
    .argumentInputPopoverInput{
        width: 80%;
    }
    
    
}

@media only screen and (min-width: 480px) and (max-width: 800px) {
    .fullGrid{
        grid-template-columns: repeat(2, 1fr) ;
        grid-template-rows: repeat(3, 1fr) ;
        grid-column-gap: 0px ;
        grid-row-gap: 0px ;
    }
    .fullGridCard:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
    .fullGridCard:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
    .fullGridCard:nth-child(3) { grid-area: 2 / 1 / 3 / 2; }
    .fullGridCard:nth-child(4) { grid-area: 2 / 2 / 3 / 3; }
    .fullGridCard:nth-child(5) { grid-area: 3 / 1 / 4 / 3; }  
    .sessionDetailsResourceCard {
        /* width: 35vw; */
        height: 15vh;
        margin: clamp(5px, 1vw, 20px);
    }
    .argumentInputPopover{
        --width:60vw;
        --height:20vh;
    }
    .argumentInputPopoverTitle{
        font-size: 2em;
    }
    .argumentInputPopoverInput{
        width: 80%;
        font-size: 1.8em;
    }

}