@media (prefers-color-scheme: dark) {
    .threepointicon {
        color: rgb(0, 0, 0);
    }
}

.threepointbutton::part(native) {
    background-color: var(--ionbackgroundcoloro);
    box-shadow: none;
    border-radius: 5px;
}

.BackBtn {
    /* margin-left: 55px; */
    display: inline-block;
    position: absolute;
    left: 0;
}

.dwPopItem::part(native) {
    background: transparent;
    color: var(--txt-color);
    --border-style: none;
}
.dwPopItem{
    font-size: 12px;
}
.fileViewerTutorialIcon{
    font-size: 35px;
    margin-right: 15px;
    cursor: pointer;
}
.fileViewerButtonIcon {
    color: var(--txt-color);
}

.downloadPopover {
    background: var(--bg-content-color);
}

.downloadPopover.list-ios {
    background: var(--bg-content-color);
}

.fileViewerToolbar {
    text-align: center;
    --background: var(--bg-content-color);
}

.fileViewerBackButtonIcon {
    font-size: xx-large;
}

.fileViewerButtonIcon {
    font-size: xx-large;
    margin-right: 15px;
}

.titolo {
    display: inline-block;
    color: var(--txt-color)
}

.popoverOptions .popover-content {
    top: 5% !important;
    max-width: 70%;
    width: auto;
    right: 0;
    left: auto !important;
    
}


.popoverOptions .popover-viewport {
    display: contents;
}

.popoverOptions {
    --background: var(--bg-content-color);

}

.kebabMenu,
.BackBtn {
    color: var(--txt-color);
}
@media only screen and (max-width: 480px) {
    .BackBtn {
        /* margin-left: 55px; */
        position: unset;
        left: 0;
    }
    .titolo {
    display: flex;
    }
}