.sketchWordTitleDiv {
    text-align: center;
    margin: 10px;
}

.sketchWordTitle {
    font-size: 30px;
}

.sketchRadioButton {
    width: 70px;
    height: 70px;
    --color: transparent;
    --color-checked: transparent;
}

.canvasContainer {
    display: flex;
    justify-content: center;
}

.sketchCanvas {
    max-width: 700px;
    max-height: 700px;
    overflow: hidden;
}

.sketchButtons {
    display: flex;
    margin-top: 10px;
    margin-right: 100px;
    justify-content: center;
}

.sketchColorPaletteList {
    margin-left: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}

.sketchRadioLabel {
    border: 5px solid rgba(255, 255, 255, .5);
    border-radius: 50%;
    width: 100px;
    height: 100px;

    margin-bottom: 10px;
}

.sketchRange {
    --bar-height: 30px;
    --knob-size: 0px;
    --bar-border-radius: 15px
}

@media screen and (max-width: 700px) {
    .sketchWordTitle {
        font-size: 20px;
    }

    .sketchColorPaletteList {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 10px;

        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;

        margin-left: 0px;
    }

    .sketchRadioLabel {
        border: none;
        width: auto;
        height: 0;
        padding-bottom: 100%;
        margin-bottom: 0;
    }

    .sketchButtons {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .sketchCanvas {
        align-self: center;
        max-width: 90%;
        max-height: 500px;
    }

    .canvasContainer {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

}

.canvasContainer>div {
    display: flex;
}