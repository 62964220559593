@import url("../../assets/colorsCSS/colorPalettes.css");
@import url("../../assets/fonts/Nunito/stylesheetNunitoRegular.css");
@import url("../../assets/fonts/Nunito/stylesheetNunitoSemiBold.css");
@import url("../../assets/fonts/Roboto/stylesheet.css");

.sideHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 43px 0 155px 0;

}

.sideMenu {
    z-index: 1000;
}

.sideMenuContainer::part(background) {
    background: var(--bg-content-color);
}

.md .menu-content-push {
    box-shadow: none !important;
    transform: none;
}

.sideMenuClose {
    width: 44px;
    font-size: 44px;
    color: var(--txt-color);
}

.sideMenuClose::part(native) {
    padding: 0;
    margin: 0;
    background: transparent;
    box-shadow: none;
}

.logo {
    background: url("../../assets/icons/reasy_logo_book.svg")no-repeat;
    width: 50px;
    height: 37px;
    background-size: 50px;
    margin-right: 20px;
}
.sideHeader ion-text{
    font-size: 34px;
    margin-right: 33px;
    color: var(--txt-color);
    font-family: NunitoSemiBold;
}

.navigation {
    margin-top: 155px;
}

#homeIcon,#profileItem,#settingsIcon{
    color:var(--txt-color);
    font-family: Nunito;
}

.item-inner {
    border-style: none !important;
}

.navigation ion-item {
    font-size: 17px;
    margin-left: 24px;
    color:var(--txt-color)
}
.sideMenuItem{
    margin-left: 0px !important;
}
.sideMenuItem::part(native) {
    cursor: pointer;
    --border-style: none;
    background: var(--bg-content-color);
}
.sideMenuItem::part(native):hover {
    color: var(--callToAction-color);
}
.sideMenuRedeemLicenseAccordionGroup{
    width: 100%;
}
.redeemBookPopover{
    --width:35vw;
    --heigth:10vh;
}
.redeemBookPopoverDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 25px;
}
.redeemBookPopoverTitle{
    font-size: 2vw;
    margin: 0;
    color:var(--txt-color);
}
.redeemBookPopoverDescription{
    font-size: 1vw;
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--txt-color);
}
.redeemBookItem{
    border: 2px solid rgb(122, 122, 255);
    border-radius: 30px;
    margin-bottom: 5px;
    width: 100%;
}
.redeemBookInput{
    --highlight-color-focused:rgb(122, 122, 255);
}
.redeemBookErrorLabel{
    margin: 0;
    color: red;
    font-size: .9vw;
    margin-bottom: 15px;
}
.redeemBookPopoverButton{
    width: 35%;
    --background: red;
    --border-radius: 25px;
}
.sideMenu::part(container) {
    width: 306px;
}

.logo::part(image) {
    height: auto;
}

.sideMenuFooter {
    margin-bottom: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    color: var(--txt-color);
}

.sideMenuFooterElement {
    justify-content: center;
    margin-top: 14px;
    font-size: 15px;
    text-align: center;

}
.sideMenuFooterElement>a {
    color:var(--callToAction-color);

}


.sideMenuBtn {
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    width: 90px;
    height: -webkit-fill-available;
    position: fixed;
    background-color: var(--bg-content-color);
}

.sideMenuBtnBurger {
    margin-left: 21px;
    margin-right: 25px;
    margin-top: 43px;
    font-size: 44px;
    color:var(--txt-color);
}


@media (prefers-color-scheme: dark) {
    :root {
        --bg-content-color: #2f2f2f;
        --txt-color: #F4F4FF;
    }


}

@media (prefers-color-scheme: light) {
    :root {
        --bg-content-color:#fff;
        --txt-color: #121212;
    }
}