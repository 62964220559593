.dictionaryWord {
    text-align: center;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* Don't forget this one */

}

.dictionaryWordAndTTSDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;

}

.dictionaryWordTTSButton {
    margin-left: auto;
    margin-right: 0;
    --color: var(--txt-color)
}

.dictionaryMeaningsDiv {
    max-height: 300px;
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: 7px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
}

.dictionaryLoadingDiv {
    /* height: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

:root.light-mode {
    .dictionaryLoadingSpinner {
        --background-rgb: 0,0,0;
    }
}

@media (prefers-color-scheme: light) {

    .dictionaryMeaningsDiv {
        scrollbar-color: var(--ion-color-medium-tint) black;
    }

    .dictionaryMeaningsDiv::-webkit-scrollbar {
        background: black;
        /* color: yellow; */
        width: 7px;
        /* height: 10px; */
    }

    .dictionaryMeaningsDiv::-webkit-scrollbar-thumb {
        background: var(--ion-color-medium-tint);
        border-radius: 10px;
        -webkit-border-radius: 10px;
    }
}

@media (prefers-color-scheme: dark) {
    .dictionaryMeaningsDiv {
        scrollbar-color: var(--ion-color-step-500) white;
    }

    .dictionaryLoadingTtsSpinner {
        color: white
    }

    .dictionaryMeaningsDiv::-webkit-scrollbar {
        background: white;
        width: 7px;
    }

    .dictionaryMeaningsDiv::-webkit-scrollbar-thumb {
        background: var(--ion-color-step-500);
        border-radius: 10px;
        -webkit-border-radius: 10px;
    }
}

.dictionaryMeaningDiv {
    text-align: start;
}

.dictionaryDefinitionDiv {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid gray;
    text-align: start;
}

.dictionarySynonymsAntonymsDiv {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.dictionarySynonymsDiv {
    flex: 1;
}

.dictionaryAntonymsDiv {
    flex: 1;
}

.dictionaryWordChip {
    --background: var(--ion-color-dark-contrast);
}

@media (prefers-color-scheme: light) {



    .dictionaryLoadingTtsSpinner {
        color: black;
    }
}

@media (prefers-color-scheme: dark) {


    .dictionaryLoadingTtsSpinner {
        color: white;
    }
}

.dictionaryLoadingTtsSpinner {
    height: 20px;
    width: 20px;
}