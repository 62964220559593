.flashcardsPage{
  display: flex;
  align-items: center
}
.flashcardsMainDiv{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 22px;
  width: 70vw;
  height: 100%;
}
.flashcardsTitle{
  font-family: Nunito Bold;
  font-weight: bold;
  font-style: italic;
  font-size: 1rem;
  /* margin-top: 2em; */
}

.flashcardsCTAPar{
  font-size: .8rem;
}

.flaschardsModeDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}
.flashcardsModePar{
  font-size: 1rem;
}
.fcCard {
  /* background-color: transparent; */
  width: 40vw;
  height: 50vh;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
.fcRemoveIcon{
  position: absolute;
  top:10px;
  left: 10px;
  cursor: pointer;
  color:red;
}
.fcCounter{
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #000;
  font-size: 1rem;
  position: absolute;
}
  /* This container is needed to position the front and back side */
.fcCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform .8s;
  transform-style: preserve-3d;
}

.fcCardContentPar{
  font-size: 1.1rem;
}
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
.fcCardClicked .fcCardInner {
  transform: rotateY(180deg);
}
  
  /* Position the front and back side */
.fcCardFront, .fcCardBack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
  
  /* Style the front side (fallback if image is missing) */
.fcCardFront {
  /* background-color: #ccc; */
  color: black;
  font-family: Nunito Bold;
}
  
  /* Style the back side */
.fcCardBack {
    /* background-color: dodgerblue; */
    color: black;
    transform: rotateY(180deg);
}
.bottomButtons{
    position: absolute;
    bottom: 10px;
}
.fcButton{
  position: absolute;
  font-size: 52px;
  cursor: pointer;
}
.fcPrevious{
  left: 0px;
}
.fcNext{
  right: 0px;
}

.flashcardsContainer {
  position: relative;  
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 20% auto 0; */
}

.fcCard {
  width: 30vw;
  height: 40vh;
  
  position: absolute;
  top: 0%;
  z-index: 2;

  background: #eee;
  border-radius: 30px;
  padding: 40px;
  box-shadow: 0 0 20px #4f4f4f;
  /* transform: translate(-50%,-50%); */
  /* transform: translateY(0) rotate(4deg) translateX(25px) scale(1); */
  transform-origin: 0 0;
  
  transition: transform 0.6s cubic-bezier(.8,.2,.1,0.8) 0.1s,
    background 0.4s linear;
  
  cursor: pointer;
  user-select: none;
  
  :last-child { margin-bottom: 0; }
}

.card--next { 
  z-index: 5; 
  pointer-events: none !important;
  transform: translateY(-25px) rotate(4deg) translateX(25px) scale(1);
}

.card--out {
  animation: card-out 0.6s cubic-bezier(.8,.2,.1,0.8);
  transform: translateY(-50px) rotate(8deg) translateX(55px) scale(0.95);
  z-index: 1;
  background: #bbb;
}
.card--in {
  animation: card-out 0.6s cubic-bezier(.8,.2,.1,0.8);
  animation-direction: reverse;
  transform: translateY(-50px) rotate(8deg) translateX(55px) scale(0.95);
  z-index: 1;
  background: #bbb;
}
  
@keyframes card-out {
  0% { z-index: 20; transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-120%) rotate(-5deg) translateX(-40px); }
  80% { z-index: 1; }
  100% { transform: translateY(-50px) rotate(8deg) translateX(55px) scale(0.95); }
}
  
.card--current {
  cursor: auto;
  user-select: auto;
  position: relative;
  z-index: 10;
  opacity: 1;
  background: #EEE;
  transform: rotate(0deg) translateX(0%) scale(1);
}



@media only screen and (max-width: 480px) {
  .flashcardsMainDiv{
    font-size: 18px;
    width: 100vw;
  }
  .flashcardsTitle{
    margin: 0;
    max-width: 90vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .flashcardsCTAPar{
    font-size: .5;
  }
  .fcCard{
    width: 70vw;
    height: 30vh;
    padding: 20px;
  }
  .flashcardsContainer{
    height: 100%;
    justify-content: flex-start;
  }
  .fcButton{
    bottom: 5vh;
  }
  .fcPrevious{
    left: 5vw;
  }
  .fcNext{
    right: 5vw;
  }
  /* .sessionDetailsResourcesGrid{
    width: 95vw;
    grid-template-rows: 30vh 30vh;
    grid-template-columns: 45vw 45vw;
  } */
  .sessionDetailsResourceType{
    /* font-size: 1.4em; */
  }
  .sessionDetailsResourceIcon{
    width: 13vw;
    height: 13vw;
  }
}
@media only screen and (min-width: 480px) and (max-width:1025px) {
  .flashcardsMainDiv{
  width: 90vw;
  }
  .fcCard{
    width: 50vw;
  }
}

@media (prefers-color-scheme: dark) {
  .fcCardContentPar{
    color: #343434;
  }
}