@import url("../../../assets/colorsCSS/colorPalettes.css");

.homePageMainPage {
    opacity: 1;
}

[hidden] {
    display: none !important;
}

.resolution .popover-viewport {
    display: contents;
}

.resolution {
    overflow: initial;
}

.fileManagerContainer::part(scroll) {
    padding-left: 90px;
    background-color: var(--bg-page-color);
}


.fileManagerFileName {
    text-align: center;
    padding-bottom: 0;
    color: var(--txt-color);
    overflow-wrap: anywhere;
}
.pdfToMapSpinner{
    position: absolute;
    width: 50%;
    height: 50%;
}
.fileManagerSearchBar {
    padding-bottom: 0 !important;
    max-width: 80%;
}

.fileManagerTitle {
    display: inline-block;
    vertical-align: middle;
}
#openSideMenu{
    --background:var(--bg-page-color)
}
.filesManagerDropzone {
    margin-left: 50px;
}

.nextTutorialBtn {
    font-size: 12px;
}

.fileManagerItemsContainer {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -moz-box-wrap: wrap;
    -webkit-box-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flexbox-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.fileManagerItem {
    box-sizing: border-box;
    background-color: var(--bg-content-color);
    border-radius: 20px;
    color: var(--txt-color);
    margin: 12px;
    box-shadow: 0 0 13px 1px #00000036;
    max-width: 145px;
}

.fileOptions {
    cursor: pointer;
}
.notificationsIcon{
    cursor: pointer;
    color: var(--txt-color);
    animation: wiggleBig 1.5s infinite ease-in-out;
}
.notificationsIcon::after{
    content: "";
    background: red;
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 0;
}
.notificationsPopover{
    --width:25vw;
    --height:fit-content;
    --max-height:65vh;
}
.notificationsPopoverMainDiv{
    font-family: Nunito;
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    margin: 0px;
    /* background-color: red; */
}
.notificationsPopoverTitle{
    font-size: 35px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0px;
    color: var(--txt-color);
}
.notificationsPopoverContentDiv{
    padding: 15px;
}
.notificationsPopoverItem{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 10px;
    border: 1px solid var(--txt-color);
    border-radius: 25px;
    margin-bottom: 25px;
    background-color: #a7e4e8;
    color: #252525;
}
.notificationItemHeaderDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.notificationsPopoverItemTitle{
    font-size: 22px;
    margin-bottom: 0;
    margin-top: 0;
}
.notificationsPopoverItemDate{
    font-size: 12px;
    color: #7b7b7b;
}
.notificationItemDescriptionDiv{
    padding-left: 25px;
    margin-bottom: 15px;
}
.notificationsPopoverItemText{
    margin: 0;
    font-size: 15px;
    text-align: left;
    
}
.notificationItemBottomDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.notificationItemButton{
    font-family: Nunito;
    font-size: 12px;
    --background: var(--callToAction-color);
}
.notificationItemCTADiv{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    height: 0;
    padding-bottom: 15px;
}
.notificationItemCTAArrow{
    font-size: 25px;
    position: absolute;
    right: 15px;
    bottom: 15px;
    opacity: 0;
}
.notificationsPopoverItem:hover .notificationItemCTAArrow{
    opacity: 1;
}
.filesManagerHelpFabButton{
    --width: 1em;
    --height: 1em;
    --background:transparent;
    --box-shadow: none;
}
.filesManagerAddFab {
    margin-bottom: 5.9vh;
}

.filesManagerTutorialFabButton::part(native) {
    --background: transparent;

}
.filesManagerAddFabButton::part(native) {
    --background: var(--callToAction-color);

}

.filesManagerAddFabButton ion-icon {
    color: var(--selection-txt-color);
}

.filesManagerUploadCTAParagraph {
    text-align: center;
    flex: 1;
    margin-top: 60px;
    color: var(--txt-color);
}

.filesManagerTapToUploadAnimation {
    position: absolute;
    top: 20px;
    left: 35%;
    transform: rotate(90deg);
}

.fileManagerFolderCreationIcon {
    font-size: 30px;
    text-align: center;
}

.filesManagerAddFab input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.fileManagerPopoverContext {
    transform: translateX(30px);
}

.fileManagerSegmentButton {
    max-width: fit-content;
    text-transform: none !important;
    --indicator-color: transparent !important;
    --indicator-color-checked: transparent !important;
}

.filesManagerDropzoneActive {
    background: linear-gradient(90deg, gray 50%, transparent 50%),
        linear-gradient(90deg, gray 50%, transparent 50%),
        linear-gradient(0deg, gray 50%, transparent 50%),
        linear-gradient(0deg, gray 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
    padding: 10px;
    animation: border-dance 20s infinite linear;
    opacity: 0.7;
}

@keyframes border-dance {
    0% {
        background-position: 0 0, 100% 100%, 0 100%, 100% 0;
    }

    100% {
        background-position: 100% 0, 0 100%, 0 0, 100% 100%;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0px);
    }
    
    50% {
        transform: translateY(-8px);
    }

    100% {
        transform: translateY(0px);
    }

  
}
@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }

    80% {
        transform: rotate(0deg);
    }

    85% {
        transform: rotate(3deg);
    }

    95% {
        transform: rotate(-3deg);
    }

    100% {
        transform: rotate(0deg);
    }
}
@keyframes wiggleBig {
    0% {
        transform: rotate(0deg);
    }

    80% {
        transform: rotate(0deg);
    }

    85% {
        transform: rotate(15deg);
    }

    95% {
        transform: rotate(-15deg);
    }

    100% {
        transform: rotate(0deg);
    }
}
.filesManagerSegmentationBanner{
    width: 75vw;
    background-color: #2f6dc4;
    border-radius: 25px;
    margin-top: 10px;
    margin-bottom: 5px;
}
.filesManagerSegmentationTitle{
    font-size: 40px;
    text-align: center;
    font-family: Nunito;
    color: #fff;
    animation: bounce .5s forwards 1s 2;
    margin-bottom:10px
}
.filesManagerSegmentationContent{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.filesManagerSegmentationRole{
    font-size: 1vw;
    font-family: Nunito;
    margin-top: 10px;
    margin-bottom: 0px;
    color: var(--txt-color);
    text-align: center;
}
.filesManagerSegmentationRadioGroup{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 2%;
    padding-bottom: 2%;
}
.filesManagerSegmentationRadio{
    padding-right: 2%;
    padding-left: 2%;
}
.filesManagerSegmentationRadio::part(label){
    margin-inline:0px 0px;
}
.filesManagerSegmentationRadio::part(container){
    display: none;
}
.selectedRole{
    animation: translateUp .2s forwards !important;
    border: 6px solid var(--accents-color) !important;
    opacity:1 !important;
}
@keyframes translateUp {
    100%{
        transform: translateY(-15px);
    }
}
.filesManagerSegmentationCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 10vw;
    height: 10vw;
    background: var(--bg-content-color);
    border-radius: 15px;
    /* opacity:.6; */
    border: 6px solid transparent;
    margin-top: 15px;
}
.filesManagerSegmentationCardContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
}
.filesManagerSegmentationIcon{
    font-size: 5vw !important;
}
.filesManagerSegmentationButtonDiv{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
}
.filesManagerSegmentationButtonInput{
    border: 1px solid #fff;
    border-radius: 25px;
    --padding-start: 8px;
    --placeholder-color:#fff;
    margin-bottom: 5px;
    font-family: Nunito;
}
.filesManagerSegmentationButtonInput{
    --highlight-color:unset;
}
.filesManagerSegmentationButton{
    --background:var(--callToAction-color);
    font-family:Nunito;
}
.filesManagerRedeemCodeDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75vw;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
    background:#FCD9CA;
    border-radius: 15px;
}
.filesManagerRedeemLicenseInput{
    --padding-start: 8px;
}
.filesManagerRedeemLicenseInput{
    --highlight-color:unset;
    color: var(--txt-color);
    --padding-start:25px;
}

.filesManagerRedeemLicenseAccordion{
    background:var(--bg-content-color);
    width: 35vw;
    border: 1px solid var(--accents-color);
    border-radius: 15px;
}
.filesManagerRedeemLicenseAccordion {
    .ion-accordion-toggle-icon {
        color: var(--txt-color);
    }
}

.filesManagerRedeemLicenseContentDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filesManagerRedeemLicenseItem{
    color: var(--txt-color);
    --background:var(--bg-content-color);
    --background-activated:none;
    --background-focused:none;
    --background-hover:none;
    --background-hover-opacity:1;
    --color-hover:var(--txt-color);
    --color-focused:var(--txt-color);
}
.filesManagerRedeemLicenseButton{
    --background:var(--callToAction-color);
    font-family:Nunito;
    margin-bottom: 10px;
}
div.filesManagerNotificationsDivWiggle {
    animation: wiggle 2s infinite;
    margin-top: 10px;
    margin-bottom: 10px;
}

div.filesManagerNotificationsDivWiggle:hover {
    animation: none;
}

.filesManagerNotificationsAlertGrid {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.filesManagerNotificationsIllustration {
    width: calc(100vw / 3);
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
}

.filesManagerNotificationsCTAText {
    flex: 1;
    text-align: center;
    margin-top: 5px;
    color: var(--txt-color)
}

.fileManagerOcrPopoverTitle {
    font-size: larger;
    text-align: center;
    color: var(--txt-color)
}

.fileManagerOcrPopover,
.newMapPopover {
    --width: 40vw;
    --height: 35vh;
    display: flex;
    align-items: flex-end;
    display: contents;
    --background: var(--bg-content-color);
    align-items: center;
    padding: 15px;
}

.fileManagerOcrPopover .popover-content {
    background-color: var(--bg-content-color);
    align-items: center;
}


.newMapPopover #emptyMapTtl {
    width: 50%;
    margin: auto;
    margin-bottom: 20px;
    border-bottom: 1px solid #b8b8b8;
    color: var(--txt-color);
}

.newMapPopover #emptyMapTtl .native-input {
    color: var(--txt-color);
}

.fileManagerOcrPopoverTablet {
    --width: 70vw;
    --height: auto;
    display: flex;
    align-items: flex-end;
}

.fileManagerOcrPopoverTablet .popover-viewport {
    display: contents;
}


.fileManagerOcrPopoverDivTablet {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin-top: 2%;
    align-items: center;
}

.ion-delegate-host {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-content-color);
    color: #000;
}

.ion-delegate-host>.fileManagerOcrPopoverItem {
    width: 60%;
}

.fileManagerOcrPopoverItem {
    --inner-border-width: 0px;
    margin-top: 5%;
    width: 100%;
    color: var(--selection-txt-color);
    border-radius: 50px;
    /* --background: var(--callToAction-color); */
    cursor: pointer;
}

.inputFieldFile {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.fileManagerUploadIconMobile {
    color: var(--selection-txt-color);
    font-size: 55px;
}

.fileManagerOcrPopoverItemMobile {
    --inner-border-width: 0px;
    margin-top: 3%;
    width: 100%;
    color: var(--selection-txt-color);
    border-radius: 50px;
    /* --background: var(--callToAction-color); */
}

.newMapPopover>.ion-delegate-host>.fileManagerOcrPopoverItemMobile {
    width: 80%;
}

.fileManagerOcrPopoverItemTablet {
    --inner-border-width: 0px;
    margin-top: 3%;
    width: 100%;
}

.fileManagerOcrPopoverIonLabelTablet {
    margin-left: 10px;
    cursor: pointer;
    color: #fff;
}

.fileManagerOcrPopoverDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2%;
    width: 75%;
    margin-bottom: 5%;
}

.homeMenuButton {
    width: 50px;
    z-index: 20000;
}

.homeMenuButtonTablet {
    width: 50px;
    z-index: 20000;
    --color: white !important;
}

.homeMainLogo {
    height: 10vh;
    width: 85vw;
    padding: 11px;
    margin-left: 50px;
}

.homeMainGrid {
    background:transparent;
}

.homeMainLogoTablet {
    height: 6vh;
    /* width: 85vw; */
    padding: 10px;
    /* margin-left: 50px; */
}

.homeYourFilesDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.homeYourFilesDiv>ion-button {
    margin-right: 25px;
    --ripple-color: transparent !important;
    text-transform: none;
    font-size: medium;
    color: var(--callToAction-color)
}

.fileManagerList {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    background-color: unset !important;
}

.fileManagerListItem {
    margin-right: 50px;
    margin-bottom: 10px;
    border-radius: 29px;
    --background: var(--bg-content-color);
}

.fileManagerListIcon {
    color: var(--txt-color);
}

.fileManagerGridFileIcon {
    color: var(--txt-color);
    width: 100%;
}

.fileManagerGridFileIcon:hover {
    color: var(--callToAction-color);
}


.fileManagerFilePreviewImg {
    pointer-events: none;
}

.homeUploadFileCard {
    width: 350px;
    height: 250px;
    border-radius: 29px;
    background-color: var(--bg-content-color);
}

.homeUploadFileDiv {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: var(--bg-content-color);

}

.homeUploadFileParagraph {
    font-size: 30px;
    font-weight: bold;
    color: var(--txt-color)
}

.homeUploadFileImage {
    width: 90px;
    padding: 15px;
    border-radius: 100%;
    margin-right: 15px;
}
.uploadFileIconDiv{
    background-color: #419BE0
}
.uploadPdfIconDiv{
    --background: #3676c4;
}
.uploadImgIconDiv{
    --background: #3676c4;
}
.emptyMapIconDiv{
    background-color: #4169E1;
    --background: #4169E1;
}
.pdfToMapIconDiv{
    background-color: #4C41E0;
    --background: #4C41E0;
}

.homeYourFilesParagraph {
    font-size: 25px;
    font-weight: bold;
    color: var(--txt-color)
}

/* .fileManagerContainer::part(scroll){
    margin-left: 90px;
} */
.homePdfToMapDiv {
    width: 130px;
    border-radius: 100%;
    background-color: var(--callToAction-color);
}


.previewbox {
    background-image: url("../../../assets/images/previewSample.svg") no-repeat center;
    background-size: contain;
    cursor: pointer;

}

.react-joyride__popover {
    z-index: 9999;
    /* Adjust the value as needed */
}

.homePageMainPage .helpIcon {
    right: 20px
}

.helpIcon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 1000;
    cursor: pointer;
    color: var(--txt-color);

}
.helpIconColor{
    color: var(--txt-color);
}
.helpAndLayoutChange {
    display: flex;
    justify-content: center;
    align-items: center;
}

.helpAndLayoutChange .helpIcon {
    position: unset;
    z-index: 1000;
    cursor: pointer;
    color: var(--txt-color);
    margin-right: 10px;
}

.layoutChange {
    color: var(--callToAction-color);
}

.tutorialVideo {
    width: 280px;
    height: 157px;
}

.centerTxtTutorialCard {
    white-space: pre-line;
    text-align: left;
    display: flex;
    justify-content: center;
}

.resolution>.ion-delegate-host>ion-item {
    --background: transparent;
    color: var(--txt-color);
}

.resolution>.ion-delegate-host>ion-item>ion-icon {
    color: var(--txt-color);
}

.skip {
    font-size: 12px;
    border-radius: 4px;
    padding: 8px;
    color: #000;
}

.skipAll {
    font-size: 12px;
    background: transparent;
    color: #000;
    position: absolute;
    left: 25px;
    bottom: 50px;
}

.skipBtns {
    position: relative;
    z-index: 1000;
}

.disable-card {
    opacity: 0.5;
    pointer-events: none;
}

.lds-dual-ring {
    display: none;
    width: 80px;
    height: 80px;
    position: absolute;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid var(--txt-color);
    border-color: var(--txt-color) transparent var(--txt-color) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media only screen and (max-width: 480px) {
    .skipAll {
        left: 17px;
    }

    .skip {
        padding: 0;
        position: absolute;
        bottom: 12%;
    }

    .fileManagerContainer::part(scroll) {
        padding-left: 0px;
    }

    .fileManagerItem {
        /* margin: 0; */
        margin-bottom: 20px;
    }

    .logo {
        background: url("../../../assets/icons/reasy_logo_book.svg")no-repeat;
        width: 50px;
        height: 37px;
        background-size: 50px;
        margin-right: 20px;
    }

    .mobileHeader ion-text {
        font-size: 34px;
        margin-right: 33px;
        color: var(--txt-color);
        font-family: NunitoSemiBold;
    }

    .mobileHeader {
        --padding-top: 0px;
    }
    .notificationsIcon{
        font-size: 24px;
    }
    .notificationsIcon::after{
        width: 8px;
        height: 8px;
    }
    .notificationItemCTAArrow{
        opacity: 1;
    }
    .mobileHeaderToolbarHome {
        --background: transparent;
    }

    .mobileHeaderLogoContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .homeYourFilesParagraph {
        margin-left: 15px;
    }

    .fileManagerList {
        background: transparent;
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .filesManagerDropzone {
        margin-left: 0;
    }

    .fileManagerOcrPopover,
    .newMapPopover {
        --width: 80vw;
        --height: 45vh;
    }

    .fileManagerOcrPopoverDiv {
        flex-direction: column;
        align-items: center;
        background-color: var(--bg-content-color);
        width: 85%;
        padding-bottom: 15px;
    }

    .homeMenuButton {
        position: absolute;
        left: 1vw;
        width: 50px;
    }

    .filesManagerAddFab {
        margin-bottom: 10vh;
    }

    .homeMainLogo {
        height: auto;
        width: 130px;
        margin-left: 0px;
    }

    .homeMainLogoDiv {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        --background: #3626a7;
    }

    .homeHeaderToolbar {
        color: var(--txt-color);
        max-height: 120px;
        display: flex;
        --background: transparent;
        background: var(--bg-content-color);
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: -1;
    }


    .fileManagerContainerDiv {
        display: flex;
    }

    .fileManagerList {
        background: transparent;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    .fileManagerListDiv {
        display: flex;
        border-radius: 29px;
        margin-bottom: 20px;
    }

    .fileManagerListItem {
        margin-left: 5%;
        margin-right: 5%;
        width: 95%;
        border-radius: 29px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .fileManagerListIcon {
        width: 5%;
    }

    .fileManagerGridFileIcon {
        align-self: center;
    }

    .fileManagerOcrPopoverButton {
        --background: var(--callToAction-color);
        width: 75%;
    }

    .fileManagerOcrPopoverButton:hover {
        opacity: .8;
    }

    .cardContainer {
        display: flex;
        justify-content: space-evenly;
    }

    .fileManagerOcrPopoverItemMobile .sc-ion-label-ios-h {
        font-size: 16px;
        white-space: pre-wrap;
    }

    .helpIcon {
        top: 80px;
        right: 5px;
    }
    .filesManagerSegmentationBanner{
        width: 95vw;
    }
    .filesManagerSegmentationRadio{
        padding-right:0%;
        padding-left: 0%;
    }
    .filesManagerSegmentationTitle{
        font-size: 25px;
    }
    .filesManagerSegmentationCard{
        width: 23.5vw;
        height: 22vw;
        border: 3px solid transparent;
    }
    .selectedRole{
        border: 3px solid var(--accents-color) !important;
    }
    .filesManagerSegmentationRole{
        font-size: 3vw;
        margin-top: 10px;
        margin-bottom: 0px;
        color: var(--txt-color);
    }
    .filesManagerSegmentationIcon{
        font-size: 10vw !important;
    }
    .filesManagerSegmentationButtonDiv{
        width: 80%;
    }
    .filesManagerRedeemCodeDiv{
        width: 95vw;
    }
    .filesManagerRedeemLicenseAccordion{
        width: 80vw;
        border: 1px solid var(--accents-color);
        border-radius: 15px;
    }

}
/* TABLETS */
@media only screen and (min-width: 480px) and (max-width: 850px) {
    .filesManagerSegmentationBanner{
        width: 95vw;
    }
    .filesManagerSegmentationRadio{
        padding-right:0%;
        padding-left: 0%;
    }
    .filesManagerSegmentationTitle{
        font-size: 25px;
    }
    .filesManagerSegmentationCard{
        width: 23.5vw;
        height: 22vw;
        border: 3px solid transparent;
    }
    .selectedRole{
        border: 3px solid var(--accents-color) !important;
    }
    .filesManagerSegmentationRole{
        font-size: 3vw;
        margin-top: 10px;
        margin-bottom: 0px;
        color: var(--txt-color);
    }
    .filesManagerSegmentationIcon{
        font-size: 10vw !important;
    }
    .filesManagerSegmentationButtonDiv{
        width: 80%;
    }
    .filesManagerRedeemCodeDiv{
        width: 95vw;
    }
    .filesManagerRedeemLicenseAccordion{
        width: 80vw;
        border: 1px solid var(--accents-color);
        border-radius: 15px;
    }
    .notificationsIcon{
        font-size: 45px;
    }
    .notificationsIcon::after{
        width: 14px;
        height: 14px;
    }
    .notificationsPopover{
        --width: 45vw;
        --height:45vh;
    }
}


@media only screen and (max-width:450px) {

    .fileManagerOcrPopover,
    .newMapPopover {
        --width: 80vw;
        --height: 55%;
    }
    .notificationsPopover{
        --width:75vw;
        --height:40vh;
    }
    .notificationsPopoverItemTitle{
        font-size: 18px;
    }
    .notificationsPopoverItemDate{
        font-size: 10px;
    }
    .notificationsPopoverItemText{
        font-size: 14px;
    }
}

@media only screen and (max-height: 680px) {
    .fileManagerOcrPopoverTitle {
        font-size: 16px;
    }

    .fileManagerOcrPopoverItemMobile .sc-ion-label-ios-h {
        font-size: 12px;
    }
}

@media only screen and(max-device-width:840px),
only screen and (max-device-width: 768px),
only screen and (max-device-width: 717px),
only screen and(max-width:840px),
only screen and (max-width: 768px),
only screen and (max-width: 717px) {

    .homeUploadFileCard {
        width: 350px;
        height: 250px;
        border-radius: 29px;
        background-color: #ffffff;
    }

    .homeUploadFileDiv {
        display: flex;
        cursor: pointer;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .homeUploadFileParagraph {
        font-size: 30px;
        font-weight: bold;
    }

    .homeUploadFileImage {
        width: 130px;
    }

    .homeYourFilesParagraph {
        font-size: 25px;
        font-weight: bold;
    }

    /* .fileManagerContainer::part(scroll){
    margin-left: 90px;
} */

    .previewbox {
        background-image: url("../../../assets/images/previewSample.svg") no-repeat center;
        background-size: contain;
    }

    .tutorialVideo {
        width: 250px;
        height: 127px;
    }

}

@media only screen and (min-width: 540px) and (max-width: 850px) {
    .helpIcon {
        top: 100px;
        right: 5px;
    }
}

@media only screen and (min-width:900px) {}

.mobileHeader ion-text {
    font-size: 45px;
    margin-right: 33px;
    color: var(--txt-color);
    font-family: NunitoSemiBold;
}

.mobileHeader {
    height: 9vh;
    width: 100vw;
}

.mobileHeaderToolbarHome {
    --background: transparent;
}

.homeHeaderToolbar {
    color: var(--txt-color);
    max-height: 120px;
    background: transparent;
    display: flex;
    --background: var(--bg-content-color);
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: -1;
    margin: 0;
}
.mobileHeaderContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;
}
.mobileHeaderLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}

@media only screen and (min-width: 540px) and (max-width: 850px) {
    .filesManagerDropzone {
        margin: 0;
    }

    .cardContainer {
        margin-left: 50px;
    }

    .homeYourFilesParagraph {
        margin-left: 60px;
    }


    .logo {
        background: url("../../../assets/icons/reasy_logo_book.svg")no-repeat;
        width: 60px;
        height: 45px;
        background-size: 60px;
        margin-right: 20px;
    }


    .header-ios .homeHeaderToolbar:last-of-type {
        --border-width: 0;
    }


    .fileManagerOcrPopover,
    .newMapPopover {
        --width: 80vw;
        --height: 45vh;
    }

    .fileManagerOcrPopoverTitle {
        font-size: 30px;
    }

    .fileManagerOcrPopoverIonLabelTablet {
        padding: 10px;
    }

    .fileManagerOcrPopoverItemMobile {
        margin-top: 0;
        margin-bottom: 3%;
        font-size: 3vw;
    }

    .fileManagerContainer::part(scroll) {
        padding-left: 0;
    }
}

@media (orientation: landscape) {
    .fileManagerOcrPopoverItemMobile {
        font-size: 16px;
        width: 100%;
    }
}

@media (prefers-color-scheme: dark) {
    :root {
        --bg-content-color: #32324B;
        /* Use for the background of the website or design.*/
        --bg-page-color: #19162F;
        --txt-color: #F4F4FF;
        /*  Use for text and content.*/
        --accents-color: #3626A7;
        /* Use for highlights, decorative elements, or accents. */
        --callToAction-color: #FF331F;
        /* Use for call-to-action buttons, important elements, or links. */
        --fileView-btn: #fff;
        --fileView-btn-color: #121212;
        --neutral-color: #8E8E8E;
        /* Use for borders or neutral elements. */
        --positive-color: #3baa3b;
        --selection-txt-color: #fff;

    }
    .uploadFileIconDiv{
        background-color: #63bdc2;
    }
    .mapToPdfIconDiv{
        background-color: #6495ED;
    }
    .emptyMapIconDiv{
        background-color: #7B68EE;
        --background: #7B68EE;
    }
    .filesManagerSegmentationBanner{
        background-color: #2f6dc4;
    }
    .selectedRole{
    border: 6px solid #65c3eb !important;
    }
    .notificationsPopover{
        --backdrop-opacity:.5;
    }
}

@media (prefers-color-scheme: light) {
    :root {
        --bg-content-color: #fff;
        /* Use for the background of the website or design.*/
        --bg-page-color: #F4F4FF;
        --txt-color: #252525;
        /*  Use for text and content.*/
        --accents-color: #3626A7;
        /* Use for highlights, decorative elements, or accents. */
        --callToAction-color: #FF331F;
        /* Use for call-to-action buttons, important elements, or links. */
        --fileView-btn: #fff;
        --fileView-btn-color: #121212;
        --neutral-color: #8E8E8E;
        /* Use for borders or neutral elements. */
        --positive-color: #3baa3b;
        --selection-txt-color: #fff;

    }
}