@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Regular.eot');
    src: url('Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('Roboto-Regular.woff2') format('woff2'),
        url('Roboto-Regular.woff') format('woff'),
        url('Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

