.paymentCompletedContent{
    --background: var(--bg-page-color);
}
.paymentCompletedMainGrid {
    max-width: 800px;
    background: var(--bg-page-color);
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.paymentCompletedSuccessContainer{
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.paymentCompletedSuccessIcon {
    width: 150px;
    height: 150px;
}

.paymentCompletedSuccessText {
    text-align: center;
    font-size: larger;
}

.paymentCompletedSuccessButtonsDiv {
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.paymentCompletedSuccessDiscoverButton {
    text-transform: none;
}


.paymentCompletedMainDiv{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
}
.paymentCompletedHeaderDiv{
    font-size:38px;
    margin-bottom: 25px;
    text-align: center;
}
.paymentCompletedButtonsDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.paymentCompletedInvoiceText{
    cursor: pointer;
    color: #626262;
    font-size: 16px;
    max-width: 50%;
    margin-bottom: 25px;
}
.paymentCompletedCTAButton{
    font-size: 25px;
    font-family: Nunito Bold;
    --background: #ff1e1e;
    --border-radius: 15px;
    --padding-top:35px;
    --padding-bottom:35px;
    --padding-start:40px;
    --padding-end:40px;

}
.paymentProcessingMainDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
}

.paymentCompletedErrorDiv{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loadingBlob {
    height: 60vh;
}
@media only screen and (max-width:480px){
    .paymentCompletedHeaderDiv{
        font-size: 23px;
    }
    .paymentCompletedInvoiceText{
        font-size: 12px;
        max-width: 90%;
    }
    .paymentCompletedCTAButton{
        max-width: 90vw;
        font-size: 20px;
        --padding-top:20px;
        --padding-bottom:20px;
        --padding-start:30px;
        --padding-end:30px;
    }
}
@media only screen and (min-width:480px) and (max-width:800px){
    .paymentCompletedHeaderDiv{
        font-size: 23px;
    }
    .paymentCompletedInvoiceText{
        font-size: 12px;
        max-width: 90%;
    }
    .paymentCompletedCTAButton{
        max-width: 90vw;
        font-size: 4.5vw;
        --padding-top:20px;
        --padding-bottom:20px;
        --padding-start:30px;
        --padding-end:30px;
    }
}