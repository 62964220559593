@import '../../assets/colorsCSS/colorPalettes.css';
.selectionPopoverOuterDiv {
    z-index: 1000;
    transition: opacity 0.3s ease;
    scrollbar-width: thin;
    scrollbar-color: var(--accents-color) transparent;
}

.selectionPopoverOuterDiv::-webkit-scrollbar {
    width: 6px;
}

.selectionPopoverOuterDiv::-webkit-scrollbar-track {
    background: transparent;
}

.selectionPopoverOuterDiv::-webkit-scrollbar-thumb {
    background-color: var(--accents-color);
    border-radius: 3px;
}

.selectionPopoverInnerDiv {
    background: var(--bg-content-color);
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}



.dictonaryPopover {
    overflow-y: auto;
    max-height: calc(100vh - 40px);
}
.selectionPopoverOuterDiv {
    position: absolute;
    top: 100px;
    
    /* display: block;
    margin-left: auto;
    margin-right: auto; */
    /* background-color: red; */
    width:700px; 
    pointer-events: none;  
    opacity: 0;
    -webkit-transition: all .3s ;
    -moz-transition: all .3s ;
    -o-transition: all .3s ;
    -ms-transition: all .3s ;
    transition: all .3s   ;
    }

.selectionPopoverInnerDiv {
    position: relative;
    pointer-events: visible;
    
    /* margin-left: -60px; */
    /* display: block; */
    /*margin: 0 auto; */
    
    text-align: left;
    
    transition-property:all 0.5s;
    border-radius: 10px;
    

    -webkit-box-shadow: 0px 10px 28px -6px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 28px -6px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 28px -6px rgba(0,0,0,0.75);
}

.selectionPopoverInnerDiv::after {
    content: "";
    position: absolute;
    bottom: 100%;    
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
}
.dictonaryPopover{
    --background: var(--bg-content-background);
}
.shiftText{
    transform: translateX(-200px);
}

.selectionPopoverNavigateBackDiv {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 10;
    height: 40px;
    width: 40px;

    background: #AAAAAA80;
    border-radius: 40px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0;
}

.selectionPopoverNavigateBackIcon {
    position: absolute;
    color: white;
}

.selectionPopoverInnerDiv {
    background: var(--bg-content-background);
    color: var(--txt-color);
}
.selectionPopoverInnerDiv::after {
    border-color: #31313100;
}


/* width */
::-webkit-scrollbar {
    width: 5px;       
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 10px;
    height: 25px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b5b5b5 ; 
    border-radius: 10px;    
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #999; 
  }.selectionPopoverOuterDiv {
    position: fixed;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    pointer-events: auto;
  }
  
  .selectionPopoverInnerDiv {
    position: absolute;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    max-width: 450px;
    width: 100%;
    transform-origin: top center;
  }
  
  .dictonaryPopover {
    border-radius: 0 8px 8px 0;
    overflow: hidden;
  }
  
  @media (max-width: 500px) {
    .selectionPopoverInnerDiv {
      position: fixed;
      left: 50% !important;
      transform: translateX(-50%);
      width: calc(100% - 32px);
      max-width: none;
      margin: 16px !important;
    }
  }