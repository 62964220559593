.sessionsAudioContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #dbe9ff;
    border-radius: 15px;
    padding: 25px;
    width: 500px;
}
.sessionsAudioPlayIcon{
    font-size: 32px;
    cursor: pointer;
    color: #2b2b2b;
}
.sessionsAudioVolumeIcon{
    font-size: 32px;
    cursor: pointer;
    pointer-events: all;
    color: #2b2b2b;
}
.sessionsAudioVolumeDiv{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
}
.sessionsAudioControlsDiv{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 45px;
    margin-bottom: 20px;
}
.sessionsAudioVolumeControl{
    cursor: pointer;
    pointer-events: none;
    position: absolute;
    padding-right: 55px;
    width: 0px;
    height: 100%;
    right: -100%;
    opacity: 0; /* Hide the slider initially */
    transition: all 0.2s ease-in-out;
  }
.sessionsAudioVolumeDiv:hover .sessionsAudioVolumeControl{
    opacity: 1; /* Show the slider on hover */
    pointer-events: all;
    right: -10px;
    width: 100px;
}

.sessionsAudioVolumeHoverArea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Cover the entire parent div */
    cursor: pointer; /* Indicate hover interaction */
  }
  
.sessionsAudioCTA{
    color: #2b2b2b;
    height: 0px;
    margin-top: 0px;
    font-family: Nunito;

}
/* ION RANGE STYLING */
.sessionsAudioRange{
    width: 100%;
    margin-top: 45px;
    --knob-size:20px;
    --knob-background:#ff77a2;
    --height:20px;
    --bar-background:#aed0ff;
    --bar-background-active:#fc9fbc;
}
#current-time{
    padding-right: 15px;
}
#duration-time{
    padding-left: 15px;
}
.sessionsAudioVolumeControl{
    --knob-size:22px;
    --knob-background:#ff77a2;
    --height:16px;
    --bar-height: 8px;
    --bar-background:#aed0ff;
    --bar-background-active:#fc9fbc;
}
.sessionsAudioSpeedSelect{
    position: absolute;
    left: 15px;
    width: 75px;
    color: #2b2b2b;

}
.sessionsAudioSpeedSelectPopover{
    --width:fit-content;
}
.sessionsAudioVolumeDiv{
    position: absolute;
    right: 0;
}

#current-time{
    color: #2b2b2b;
}
#duration-time{
    color: #2b2b2b;
}


@media only screen and (max-width: 480px) {
    .sessionsAudioContainer{
        width: 70vw;
        margin-top: 15vh;
    }
    #current-time{
        font-size: 15px;
        color: #2b2b2b;
    }
    #duration-time{
        font-size: 15px;
        color: #2b2b2b;
    }
    .sessionsAudioPlayIcon{
        font-size: 25px;
    }
    .sessionsAudioVolumeIcon{
        font-size: 20px;
    }
    .sessionsAudioSpeedSelect{
        font-size: 15px;
    }
    .sessionsAudioVolumeControl{
        cursor: pointer;
        pointer-events: none;
        position: absolute;
        padding-right: 50px;
        width: 0px;
        height: 100%;
        right: -100%;
        opacity: 0; /* Hide the slider initially */
        transition: all 0.2s ease-in-out;
      }
    .sessionsAudioVolumeDiv:hover .sessionsAudioVolumeControl{
        opacity: 1; /* Show the slider on hover */
        pointer-events: all;
        right: -10px;
        width: 50px;
    }
    .sessionsAudioVolumeControl{
        --knob-size:16px;
        --knob-background:#ff77a2;
        --height:16px;
        --bar-height: 10px;
        --bar-background:#aed0ff;
        --bar-background-active:#fc9fbc;
    }    
    
}