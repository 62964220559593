/* DEFAULT */



:root.dark-mode{
    --bg-content-color:#32324B;/* Use for the background of the website or design.*/
    --bg-page-color:#19162F;
    --txt-color:#F4F4FF;/*  Use for text and content.*/
    --accents-color:#3626A7;/* Use for highlights, decorative elements, or accents. */
    --callToAction-color:#FF331F;/* Use for call-to-action buttons, important elements, or links. */
    --fileView-btn:#fff;
    --fileView-btn-color:#121212;
    --neutral-color:#8E8E8E;/* Use for borders or neutral elements. */
    --positive-color:#3baa3b;
    --selection-txt-color:#fff;
    --white-and-black-gradient:linear-gradient(to right,#ffffff00,#32324b,);
    --light-grey:#404058;
}
:root.light-mode{
    --bg-content-color:#fff;/* Use for the background of the website or design.*/
    --bg-page-color:#F4F4FF;
    --txt-color:#252525;/*  Use for text and content.*/
    --accents-color:#3626A7;/* Use for highlights, decorative elements, or accents. */
    --callToAction-color:#FF331F;/* Use for call-to-action buttons, important elements, or links. */
    --fileView-btn:#fff;
    --fileView-btn-color:#121212;
    --neutral-color:#8E8E8E;/* Use for borders or neutral elements. */
    --positive-color:#3baa3b;
    --selection-txt-color:#fff;
    --white-and-black-gradient:linear-gradient(to right,#ffffff00,#fff);
    --light-grey:#e8e8e8;

}

/* TRICHROMACY */
:root.deuteranomaly{
    --bg-content-color:#DCE1E8;/* Use for the background of the website or design.*/
    --bg-page-color:#C1C1C1;
    --txt-color:#252525;/*  Use for text and content.*/
    --accents-color:#FFC857;/* Use for highlights, decorative elements, or accents. */
    --callToAction-color:#C34A36;/* Use for call-to-action buttons, important elements, or links. */
    --fileView-btn:#C34A36;
    --fileView-btn-color:#252525;

    --neutral-color:#A9A9A9;/* Use for borders or neutral elements. */
    --positive-color:#FFD700;
    --selection-txt-color:#252525;
    --light-grey:#e8e8e8;


}

:root.tritanomaly{
    --bg-content-color:#E8E8E8;/* Use for the background of the website or design.*/
    --bg-page-color:#B1B1B1;
    --txt-color:#1E1E1E;/*  Use for text and content.*/
    --accents-color:#FFC857;/* Use for highlights, decorative elements, or accents. */
    --callToAction-color:#2A9D8F;/* Use for call-to-action buttons, important elements, or links. */
    --fileView-btn:#2A9D8F;
    --fileView-btn-color:#252525;

    --neutral-color:#6F6F6F;/* Use for borders or neutral elements. */
    --positive-color:#6495ED;
    --selection-txt-color:#1E1E1E;
    --light-grey:#e8e8e8;


}

:root.trichromacy{
    --bg-content-color:#D7E5FF;/* Use for the background of the website or design.*/
    --bg-page-color:#C1C1C1 ;
    --txt-color:#262626;/*  Use for text and content.*/
    --accents-color:#BFD7EA;/* Use for highlights, decorative elements, or accents. */
    --callToAction-color:#F05D5E;/* Use for call-to-action buttons, important elements, or links. */
    --fileView-btn:#F05D5E;
    --fileView-btn-color:#262626;
    --light-grey:#e8e8e8;


    --neutral-color:#A9A9A9;/* Use for borders or neutral elements. */
    --positive-color:#3baa3b;
    --selection-txt-color:#262626;
    --light-grey:#e8e8e8;


}

/* DICHROMACY */
:root.protanopia{
    --bg-page-color: #6BAED6;/* Use for the background of the website or design.*/
    --bg-content-color:#F7D6D6;
    --txt-color:#000;/*  Use for text and content.*/
    --accents-color:#7570B3;/* Use for highlights, decorative elements, or accents. */
    --callToAction-color:#FF8C00;/* Use for call-to-action buttons, important elements, or links. */
    --fileView-btn:#FF8C00;
    --fileView-btn-color:#000;

    --neutral-color:#2B2B2B;/* Use for borders or neutral elements. */
    --positive-color:#00BFFF;
    --selection-txt-color:#000;
    --light-grey:#e8e8e8;


}

:root.deuteranopia{
    --bg-page-color: #6BAED6;/* Use for the background of the website or design.*/
    --bg-content-color:#F7D6D6;
    --txt-color:#1E1E1E;/*  Use for text and content.*/
    --accents-color:#FFC857;/* Use for highlights, decorative elements, or accents. */
    --callToAction-color:#FF8C00;/* Use for call-to-action buttons, important elements, or links. */
    --fileView-btn:#FF8C00;
    --fileView-btn-color:#1E1E1E;

    --neutral-color:#2B2B2B;/* Use for borders or neutral elements. */
    --positive-color:#FFD700;
    --selection-txt-color:#1E1E1E;
    --light-grey:#e8e8e8;

}

:root.tritanopia{
    --bg-page-color:#D7E5FF;/* Use for the background of the website or design.*/
    --bg-content-color:#FFC857;
    --txt-color:#262626 ;/*  Use for text and content.*/
    --accents-color: #BFD7EA;/* Use for highlights, decorative elements, or accents. */
    --callToAction-color:#F05D5E;/* Use for call-to-action buttons, important elements, or links. */
    --fileView-btn:#F05D5E;
    --fileView-btn-color:#262626;

    --neutral-color:#A9A9A9;/* Use for borders or neutral elements. */
    --positive-color:#6495ED;
    --selection-txt-color:#262626;
    --light-grey:#e8e8e8;


}

/* MONOCHROMACY */
:root.monochromacy{
    --bg-content-color:#F7F7F7;/* Use for the background of the website or design.*/
    --bg-page-color:#D9D9D9;
    --txt-color:#262626 ;/*  Use for text and content.*/
    --accents-color:  #7570B3;/* Use for highlights, decorative elements, or accents. */
    --callToAction-color:#FF8C00;/* Use for call-to-action buttons, important elements, or links. */
    --fileView-btn:#FF8C00;
    --neutral-color:#2B2B2B;/* Use for borders or neutral elements. */
    --positive-color:#3baa3b;
    --selection-txt-color:#262626;
    --light-grey:#e8e8e8;

}

@media (prefers-color-scheme: dark) {
    :root,
    :root.dark-mode{
        --bg-content-color:#32324B;/* Use for the background of the website or design.*/
        --bg-page-color:#19162F;
        --txt-color:#F4F4FF;/*  Use for text and content.*/
        --accents-color:#3626A7;/* Use for highlights, decorative elements, or accents. */
        --callToAction-color:#FF331F;/* Use for call-to-action buttons, important elements, or links. */
        --fileView-btn:#fff;
        --fileView-btn-color:#121212;
        --neutral-color:#8E8E8E;/* Use for borders or neutral elements. */
        --positive-color:#3baa3b;
        --selection-txt-color:#fff;
        --logo-color:#F4F4FF;
        --light-grey:#404058;
        --white-and-black-gradient:linear-gradient(to right,   #ffffff00,#32324b,)

    }
}

@media (prefers-color-scheme: light) {
    :root,
    :root.light-mode{
        --bg-content-color:#fff;/* Use for the background of the website or design.*/
        --bg-page-color:#F4F4FF;
        --txt-color:#252525;/*  Use for text and content.*/
        --accents-color:#3626A7;/* Use for highlights, decorative elements, or accents. */
        --callToAction-color:#FF331F;/* Use for call-to-action buttons, important elements, or links. */
        --fileView-btn:#fff;
        --fileView-btn-color:#121212;
        --neutral-color:#8E8E8E;/* Use for borders or neutral elements. */
        --positive-color:#3baa3b;
        --selection-txt-color:#fff;
        --logo-color:#3626a7;
        --light-grey:#e8e8e8;
        --white-and-black-gradient:linear-gradient(to right, #ffffff00,#fff)


    }
}