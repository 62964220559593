.ambassadorContent{
    font-size: 20px;
    --background:inherit;
}
.ambassadorTitleDiv{
    display: flex;
    flex-direction: column;
    max-height: 10vh;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-top: 5vh;
}
.ambassadorTitlePar{
    font-size: 2em;
    max-height: 10vh;
    margin: 0;
}
.ambassadorInfoDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5vh;
}
.ambassadorReferralCodePar{
    text-align: center;
    font-family: Nunito-Bold;
}
.ambassadorCodeHeader{
    text-align: center;
}
.ambassadorLinkHeader{
    text-align: center;
}
.ambassadorLinkSection{
    border-top: 1px solid #2b2b2b;

}
.ambassadorLinkDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}
.ambassadorLinkPar{
    font-style: italic;
    text-decoration: underline;
    font-size: 20px;
    color: #2196F3;
    margin-left: 15px;
}
.ambassadorLinkCopyIcon{
    cursor: pointer;
    margin-left: 15px;
    
}
.ambassadorDivder{
    margin-bottom: 15px;
    background:inherit;
}
/* INVITED USERS TABLE */

.ambassadorTable{
    width: 60vw;
    border: 2px solid black;
    border-radius: 15px;
    max-height: 30vh;
    overflow-y: scroll;
    padding-top: 0;
}
.ambassadorTableHeaderRow{
    position: fixed;
    width: 60vw;
    text-align: center;
    border-bottom: 1px solid #7d7d7d;
    z-index: 1;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-top: 10px;
    padding-bottom: 10px;

}
.ambassadorTableContentDiv{
    margin-top: 65px;
}
.ambassadorTableRow{
    border-bottom: 1px solid #c7c7c7;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.ambassadorReferredUserName{
    font-family: Nunito-bold;
}

@media only screen and (max-width: 480px) {
    .ambassadorContent{
        font-size: 16px;
    }
    .ambassadorTitlePar{
        white-space: pre;
    }
    .ambassadorReferralCodePar{
        font-size: 18px;
    }
    .ambassadorLinkDiv{
        flex-direction: column;
    }
    .ambassadorLinkPar{
        font-size: 14px;
        margin: 0;
    }
    .ambassadorLinkCopyIcon{
        margin: 0;
        padding: 10px;
    }
    .ambassadorDivder{
        min-height: 1px;
    }
    .ambassadorTable{
        max-height:40vh
    }
    .ambassadorTableContentDiv{
        margin-top: 85px;
    }
    .ambassadorTable{
        width: 85vw;
    }
    .ambassadorTableHeaderRow{
        width: 85vw;
    }
    .ambassadorSharePar{
        margin: 15px;
        text-align: center;
    }
}

@media (prefers-color-scheme:dark) {
    .ambassadorTable{
        border-color: #c7c7c7;
    }
}