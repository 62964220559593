@import url("../../../assets/colorsCSS/colorPalettes.css");

.forgotPasswordMainGrid {
    max-width: 400px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: space-evenly;
    align-items: center;
    background: var(--bg-content-color);
    border-radius: 30px;
    box-shadow: 0 0 20px 5px #0000001c
}

.forgotPasswordTextDiv {

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.forgotPasswordForgotText {
    text-align: center;
    font-size: xx-large;
}

.forgotPasswordInputsDiv {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.forgotPasswordButtonsDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.forgotPasswordSendResetEmailButton {
    text-transform: none;
}

.forgotPasswordResetEmailSentMainDiv {
    display: flex;
    flex-direction: column;
}

.forgotPasswordResetEmailSentText {
    text-align: center;
    font-size: x-large;
}