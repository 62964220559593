.sessionsHomePage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.sessionsHomePage::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.sessionsSplitPane{
    --side-width:300px;
}
.sessionsSplitPane::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.sessionsMenuHeader{
    /* height: 6vh; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color:var(--txt-color)
}
.sessionsMenuHeaderTitle{
    text-align: center;
    width: -webkit-fill-available;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:var(--txt-color);
    background: var(--bg-content-color);
}

.iconOpenSideMenu,.sideMenuButtonImg{
 height: 24px;
 width: 24px;
 color:var(--txt-color);
 cursor: pointer;
}
.sessionSideMenuBtn{
    display: block;
}
.flagParagraph{
    font-size: 2em;
    margin: 0;
}

.sessionsMenuLanguageButton{
    --background:transparent;
    --padding-top:0;
    --padding-bottom:0;
}
.sessionsMenuLanguageButton{
    --background-activated:transparent !important;
    --background-focused-opacity:1;
    --background-focused:transparent;
    --background-hover:transparent;
    --color-activated:transparent;
}
.sessionsHomeSearchbar{
    margin-bottom: 0px;
}
.sessionsHomeSearchbar .searchbar-input-container  .searchbar-input{
background: var(--light-grey);
}
.sessionsHomeSearchbar .searchbar-input-container .searchbar-input,
.sessionsHomeSearchbar .searchbar-input-container .searchbar-search-icon,
.sessionsHomeSearchbar .searchbar-input-container .searchbar-clear-button {
    color: var(--txt-color);
}


.sideMenuContent{
    --padding-bottom:70px !important;
    --background:var(--bg-content-color)
}

.sessionsHomeContent{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    --background:var(--bg-page-color);
}

.sessionsHomeHeaderDiv{
    width: 100%;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sessionsHomeMainDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 100%;
}
.sessionsHomeOnboardingDiv{
    font-size: 20px;  /* ROOT FONT SIZE -> ALL THE NESTED "EM" UNITS DEPENDS ON THIS VALUE */
}
.sessionsHomeLogoAndCtaDiv{
    position: absolute;
    top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sessionsHomeTitle{
    font-size: clamp(1rem,5vw,2rem);
    color:var(--txt-color)
}
.sessionsHomeLogo{
    width: clamp(90px, 10vw, 100px);
    /* width: 10vh; */
}
/* ----------- NO SESSIONS VIEWS - INITIAL ONBOARDING ------------- */
.sessionsHomeOnboardingTitle{
    text-align: center;
    font-family: Nunito Bold;
    font-size: 3em;
}
.sessionsHomeOnboardingContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.sessionsHomeArgumentInputContainer{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 4px solid black;
}
.sessionsHomeArgumentInput{
    width: 80%;
    height: 2.5em;
    background-color: #FFCCDC;
    border-radius: 15px;
    margin-top: 20px;
    --padding: 15px;
    --padding-start:15px;
    font-size: 1em;
    --placeholder-color:#202020;
    --highlight-color-focused: none;
}
.sessionsHomeArgumentValuesText{
    text-align: left;
    font-size: 1em;
    margin:20px 0 15px 0;
}
.sessionsHomeArgumentValuesList{
    font-size: 1em;
    margin:0 0 1em 0;
}
.availableResourcesList{
    background: transparent;
}
.availableResourcesItem{
    --background: transparent;
}

/* .sessionsHomeArgumentDescription{
    text-align: center;
    font-size: 1em;
    margin-left: 25px;
    margin-right: 25px;
}
.sessionsHomeArgumentDescription>b{
    font-weight: bold;
    font-family: Nunito Bold;
} */
.sessionsHomeBottomDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1.2em;
}
.sessionsHomeCTAButton{
    display: flex;
    border: 3px solid #007b1a;
    border-radius: 27px;
    /* --border-radius: 25px; */
    height: 76px;
    font-weight: bold;
    font-size: 1.5em;
    background: #C7F6D1;
    color: black;
    flex-direction: column;
    margin: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.sessionsHomeUploadParagraph{
    font-size: .8em;
    cursor: pointer;
    color: #a6a6a6;
}
.disabledCTA{
    opacity: .5;
    cursor: unset;
}
/* ----------- END NO SESSIONS VIEWS - INITIAL ONBOARDING ------------*/
/* .sessionsHomeArgumentList{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    margin-top: 25px;
    height: 35vh;
    scrollbar-width: none;
} */
/* .profileButtonReasy{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
} */
 /* -------------- DEFAULT HOME PAGE ------------ */
 .sessionsHomeArgumentItem {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    height: 40px;
    border-radius: 60px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: all .5s;
    overflow: hidden;
    padding: 0 15px;
}

.sessionsHomeArgumentTitle {
    font-family: Nunito Bold;
    font-weight: bold;
    font-size: 15px;
    color: var(--txt-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    position: relative; /* Add this */
}

.sessionsHomeArgumentItem.can-slide:hover .sessionsHomeArgumentTitle {
    overflow: visible;
    animation: slideText 5s linear infinite; /* Use animation instead of transition */
}
@keyframes slideText {
    0% {
        transform: translateX(0);
    }
    45% {
        transform: translateX(-120%);
    }
    55% {
        transform: translateX(-120%);
    }
    100% {
        transform: translateX(0);
    }
}


.gradientDiv {
    background-image: transparent;
    width: 20px;
    position: absolute;
    right: 15px;
    height: 100%;
    transition: opacity .5s;
}



.sessionsHomeArgumentItem:hover .sessionsHomeArgumentArrow {
    opacity: 1;
}

.sessionsHomeArgumentItem.can-slide:hover .sessionsHomeArgumentTitle .gradientDiv {
    opacity: 0;
}
.sessionsHomeInputDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 35px;
    width: 60%;
}
.sessionsHomeNewArgumentInput{
    border: 1px solid var(--txt-color);
    border-radius: 35px;
    background-color: var(--bg-content-color);
    font-family: Nunito Bold;
    font-weight: bold;
    font-size: 1em;
    
    width: 45vw;
    height: 55px;
    --padding-start: 10px;
    
    --placeholder-color:var(--txt-color)!important;
    color: var(--txt-color)!important;
    --highlight-color-focused: none;

}
.newArgumentUploadIcon{
    font-size: 30px;
    cursor: pointer;
    padding: 15px;
    color:var(--txt-color);
}
.sessionsHomeArgumentArrow{
    font-size: 20px;
    /* width: 90px; */
    background-color: transparent;
    color:var(--txt-color);
    /* margin-right: 15px; */
    /* position: absolute; */
    right: 0;
    width: 25px;
}
.newArgumentArrow{
    font-size: 35px;
    /* width: 90px; */
    color:var(--txt-color);
    padding-right: 15px;    
    opacity: 1;
    animation: wiggle 1s infinite;
    cursor: pointer;
}
.sessionsHomeExamplesDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: clamp(1rem, 1.3vw, 1.25rem);
    width: 100%;
}
.sessionsHomeExampleDiv:not(:first-child){
    margin-left: 35px;
}
.toolbarNotch{
    --background:transparent;
}
.sessionsHomeExampleDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    width: 200px;
    height: 130px;
    padding: 20px;
    
    cursor: pointer;

    border-radius: 20px;
    border: 1px solid var(--txt-color);

    transition: all .3s;
    color: var(--txt-color);

}
.sessionsHomeExampleDiv:hover{
    background-color: #d5d5d5;
    /* color:#f3f3f3 */
}
.sessionsHomeExampleIcon{
    width: 50px;
    height: 50px;
    filter: saturate(+100%);
}
.sessionsReasyLogoButton{
    cursor: pointer;
    width: 10vh;
    height: 10vh;
}
.sessionsHomeCounterDiv{
    /* position: absolute;
    top: 10px;
    right: 10px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    border-radius: 25px;
    /* width: 50%; */
    height: 50px;
    margin-left: 10px;
    margin-right: 10px;
}
.sessionsHomeCounterPar{
    cursor: pointer;
    
}
#sessionsSideMenu{
    --background:var(--bg-content-color);
}
.sessionsSideMenuTitleContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    background: var(--bg-content-color);

}
.sessionsHomeProcessingScreen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60vw;
    height: 100vh;

}
.sessionsHomeProcessingScreenArgument{
    font-style: italic;
    font-family: Nunito Bold;
    font-size: 50px;
    text-align: center;
    width: 95vw;
}

.sessionsHomeCTABox{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding-bottom: 1.5em;
}
/* .sessionsHomeCTABox::after{
    content: "";
    display: block;
    width: 70%;
    height: 1px;
    background-color: #282828;
    margin-top: 30px;
} */
.sessionsArgumentRemoveIcon{
    position: absolute;
    left: 0px;
}

.newArgumentItem{
    background-color: #FFCCDC;
}
.sessionsHomeDayDivider{   
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 15px;
    margin-left: 8px;
    width: 90%;
    font-size: .8em;
    color: #a1a1a1;
}
.sessionsSideMenuBottomDiv{
    /* position: fixed; */
    left: 0;
    bottom: 0;
    z-index: 10000;
    width: 100%;
    height: 70px;
    background-color: #ffb7ce;
    color: #000;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    font-size: clamp(1rem, 2.5vw, 1.5rem);

    cursor: pointer;
}
.sessionsSideMenuBottomTab{
    max-width: 100%;
}
.sessionsSideMenuBottomTabContainer{
    height: 70px;
    --background:transparent;
    border:none;
}
.sessionsSideMenuBottomTab::part(native){
    padding: 0;
}
.sessionsSideMenuProfileIcon{
    font-size: 35px;
    cursor: pointer;
}
.sessionsHomeUnlockNowBtn{
    font-size: 15px;
    right: 0px;
    top: 100px;
    --background: rgb(0, 159, 0);
}
.sessionsSideMenuUserEmail{
    
}
/* ----------- DARK MODE ---------- */

@media (prefers-color-scheme: dark) {
    .sessionsSideMenuUserEmail{
        color: var(--bg-page-color);
    }
    /* .sessionsMenuHeader,.sessionsSideMenuTitleContainer{
        background: #1f1f1f;
    } */
    .sideMenuButtonImg{
        width: 24px;
    }
    /* .sideMenuContent{
        --background: #1f1f1f;
    } */
    /* #sessionsSideMenu{
        --background:#1f1f1f;
    } */
    /* .toolbarNotchSideMenu{
        --background: #1f1f1f;
    } */
    /* .sessionsMenuHeaderTitle{
        background: #1f1f1f;
    } */

    /* .sessionsHomeArgumentArrow{
        background-color: #1f1f1f;
    } */
    .sessionsHomeArgumentInputContainer{
        border: 4px solid white;
    }
    .sessionsHomeCTAButton{
        color: black;
    }
    .sessionsHomeExampleDiv:hover{
        background-color: #1f1f1f;   
    }
    .sessionsHomeArgumentInput{
        /* color: black; */
        --placeholder-color:black;  
        background-color: #FFF2C2;
        color: black;
    }
    /* .sessionsHomeNewArgumentInput{
        background-color: #1f1f1f;
    } */
    .newArgumentItem{
        background-color: #FFF2C2;
    }

}
/* ----------- END DARK MODE ---------- */


/* ----------- RESPONSIVE ---------- */

@media only screen and (max-width: 375px) {
    .sessionsHomeOnboardingDiv{
        font-size: 15px !important;
    }
}
@media only screen and (max-width: 480px) {
    /* .sessionsHomeTitle{
        font-size: 1em;
    } */
    /* .sessionsHomeLogo{
        width: 60px;
        height: 60px;
    } */
    .sessionsHomeMainDiv{
        width: 100%;
    }
    .sessionsHomeCTABox{
        width: 100%;
    }
    .sessionsMenuHeader{
        font-size: 10px;
    }
    .sessionsHomeCounterDiv{
        width: 85%;
        height: 30px;
    }
    .sessionsHomeCounterPar{
        font-size: .7em;
    }
    .sessionsHomeExamplesDiv{
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(2,1fr);
        row-gap: 10px;
        font-size: .8em;
    }
    .sessionsHomeExampleDiv{
        width: 55%;
        height: 55%;
        font-size: 1.2em;
    }
    .sessionsHomeExampleDiv:not(:first-child){
        margin: 0;
    }
    .sessionsHomeExampleIcon{
        width: 40px;
        height: 40px;
    }
    /* FIRST ONBOARDING VIEW */
    .sessionsHomeArgumentInputContainer{
        width: 90%;
    }
    .sessionsHomeInputDiv{
        width: 95%;
    }
    .sessionsHomeNewArgumentInput{
        width: 95%;
        font-size:  .8em;
    }
    .sessionsHomeArgumentInput{
        width: 88%;
    }

    /* END */
    /* EXISTING SESSIONS VIEW */
    .sessionsHomeOnboardingDiv{
        font-size: 16px;
    }
    .sessionsHomeContent{
        width: 100%;
    }
    .sessionsHomeOnboardingTitle{
        font-size: 2.5em;
    }
    .sessionsHomeArgumentItem{
        width: 95%;
    }
    .sessionsHomeArgumentList{
        width: 95vw;
    }
    .sessionsHomeArgumentTitle{
        font-size: 1em;
    }
    .sessionsReasyLogoButton{
        width: 10vw;
        height: 10vw;
    }
    /* END */

    /* SIDE MENU */
    .sessionsSideMenuBottomDiv{
        font-size: 20px;
        height: 50px;

    }
    /* END */

    .sessionsHomeUnlockNowBtn{
        font-size: 12px;
    }    
}

@media only screen and  (min-width: 480px) and (max-width: 800px) {

    /* .sessionsHomeLogo{
        width: 80px;
        height: 80px;
    } */

    /* FIRST ONBOARDING VIEW */
    .sessionsHomeArgumentInputContainer{
        width: 90%;
    }
    .sessionsHomeArgumentInput{
        width: 88%;
    }
    .sessionsHomeCounterDiv{
        width: 90%;
    }

    /* END */
    /* EXISTING SESSIONS VIEW */
    .sessionsHomeOnboardingDiv{
        font-size: 16px;
    }
    .sessionsHomeContent{
        width: 100%;
    }
    .sessionsHomeOnboardingTitle{
        font-size: 2.5em;
    }
    .sessionsHomeArgumentItem{
        width: 95%;
    }
    .sessionsHomeArgumentList{
        width: 95vw;
    }
    .sessionsHomeArgumentTitle{
        font-size: 1em;
    }
    .sessionsReasyLogoButton{
        width: 10vw;
        height: 10vw;
    }
    /* END */
    
    .sessionsHomeExamplesDiv{
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(2,1fr);
        row-gap: 10px;
        font-size: .8em;
    }
    .sessionsHomeExampleDiv{
        width: 55%;
        max-height: 55%;
        font-size: 1.2em;
    }
    .sessionsHomeExampleDiv:not(:first-child){
        margin: 0;
    }
    .sessionsHomeInputDiv{
        width: 90%;
    }
    .sessionsHomeNewArgumentInput{
        width: 100%;
    }
}
@media only screen and  (min-width: 1000px) {
    #menuBtnSplit,#openSideMenuSessions{
        display: block;
    }
    #menuBtnNoSplit,#sessionsMenubutton{
        display: none;
    }
}
@media only screen and  (max-width: 1000px) {
    #menuBtnSplit,#openSideMenuSessions{
        display: none;
    }
    #menuBtnNoSplit,#sessionsMenubutton{
        margin-left: 10px;
        display: block;
    }
}
/* ----------- END RESPONSIVE ---------- */


/* ----------- ANIMATIONS ---------- */

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}
/* taeb-switch styles */
    .taeb-switch {
    position: relative;
  }
  
  .taeb-switch:after {
    content: "";
    position: absolute;
    width: 50%;
    top: 0;
    transition: left cubic-bezier(.88, -.35, .565, 1.35) .4s;
    border-radius: 27.5px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
    background-color: #3d90ef;
    height: 100%;
    z-index: 0;
  }
  
  .taeb-switch.left:after {
    left: 0;
  }
  
  .taeb-switch.right:after {
    left: 50%;
  }
  
  .taeb-switch .taeb {
    height: auto;
    display: inline-block;
    width: 50%;
    padding: 5px 0;
    z-index: 1;
    position: relative;
    cursor: pointer;
    transition: color 200ms;
    font-size: 13px;
    font-weight: bold;
    line-height: normal;
    -webkit-user-select: none;
    user-select: none;
    /* height: 25px; */
    color: var(--txt-color);
  }
  
  .taeb-switch .taeb.active {
    color: #ffffff;
  }
  
  
  .text-center {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    align-items: center;
  }

  .wrapper {
    border-radius: 37px;
    background-color: var(--light-grey);
    padding: 8px;
    /* width: 85%; */
    max-width: 316px;
    margin-left: auto;
    margin-right: auto;
    height: 25px;
  }
  
/* ----------- END ANIMATIONS ---------- */