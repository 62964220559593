.getPremiumPopover {
    --backdrop-opacity: 0.7;
    --width:40vw;
    --height:35vh;
    font-size:14px;
}
.getPremiumPopover>div {
    height: 100%;
    background:#5566a6;

}
.getPremiumPopoverMainDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
}

.getPremiumPopoverTextParagraphTitle {
    margin: 0;
    padding: 0;
    font-size: 2em;
    color: #F4F4FF;

    text-align: center;
}
.getPremiumPopoverTextParagraphDesc{
    font-family: Nunito;
    font-size: 1em;
    color: #F4F4FF;
    text-align: center;

}
.getPremiumPopoverTextParagraphDesc>b{
    font-family: Nunito Bold;
}

.getPremiumPopoverButtonDiv {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.getPremiumPopoverButton {
    --background: #FF7043;
    text-transform: uppercase;
    font-weight: 900;
    /* color: rgb(255, 228, 228); */

}

@media only screen and (max-width: 550px) {
    .getPremiumPopover{
        --width:80vw;
        --height:65vh !important;
    }
    .getPremiumPopoverMainDiv{
        justify-content: space-evenly;
    }
    .getPremiumPopoverTextParagraphTitle {
        font-size: 1.6em;
    }
    .getPremiumPopoverTextParagraphDesc{
        font-size: 1em;
        text-align: center;
    }
    .getPremiumPopoverButtonDiv {
        margin-top: 0;
    }
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {
    .getPremiumPopover{
        --width:80vw;
        --height:35vh !important;
    }
    .getPremiumPopoverMainDiv{
        justify-content: space-evenly;
    }
    .getPremiumPopoverTextParagraphTitle {
        font-size: 1.8em;
    }
    .getPremiumPopoverTextParagraphDesc{
        font-size: 1.2em;
        text-align: center;
    }
    .getPremiumPopoverButtonDiv {
        margin-top: 0;
    }
}