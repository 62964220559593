@import url("../../../assets/colorsCSS/colorPalettes.css");

.authGetStartedPage {
    background-color: var(--bg-page-color);
    filter: blur();
    display: flex;
    justify-content: center;
}

.authGetStartedContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: -webkit-fill-available;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #ffd4c4 inset;
    -webkit-text-fill-color: #333 !important;
    transition: background-color 5000s ease-in-out 0s;
    /* Transition for background color */
    height: -webkit-fill-available;

}

.authGetStartedMainGrid {
    width: 450px;
    border-radius: 29px;
    min-height: 85vh;
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: space-evenly;
    overflow: hidden;
    background-color: var(--bg-content-color);
    box-shadow: 0 0 20px 5px #0000001c
}

.authGetStartedTextDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
}



.authGetStartedWelcomeText {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
}

.authGetStartedSubTitle {
    text-align: center;
    margin-top: -15px;
    font-size: larger;
    margin-bottom: 5vh;
}

.c {
    font-size: large;
    text-align: center;
}

.authGetStartedIllustrationDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.authGetStartedParagraph {
    text-align: center;
    font-weight: 600;
}

@keyframes wiggleHandAnimation {
    0% {
        transform: rotate(0deg);
    }

    80% {
        transform: rotate(0deg);
    }

    85% {
        transform: rotate(5deg);
    }

    95% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.authGetStartedWavingHandImg {
    object-fit: contain;

    height: 50%;
    width: 50%;

    animation: wiggleHandAnimation 1s;
    animation-iteration-count: 2;
}

.authGetStartedButtonsDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.authGetStartedEmailAndPasswordButton {
    text-transform: uppercase;
    width: 225px;
    height: 44px;
    /* UI Properties */
    background: #ff331f;
    color: var(--selection-txt-color);
    border-radius: 29px;
    opacity: 1;
}

.authGetStartedEmailAndPasswordButton:hover {
    background: #f95e50;
}

.authGetStartedContinueWithButton {
    text-transform: none;
    width: 90%;
    max-width: 400px;
    margin-bottom: 10px;
}

.authGetStartedContinueWithButtonIcon {
    margin-right: 5px;
}

.authLoginLogoTxt {
    width: 200px;
    height: 50px;
    margin-top: 30px;
}

.languageContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.authGetStartedMagicLinkButton {
    width: 165px;
    height: 44px;
    text-transform: uppercase;
    --border-width: 2px;
    --border-color: black;
}

.authGetStartedMagicLinkButtonIcon {
    margin-right: 10px;
}

.getStartedLegalTermsDiv {
    font-size: small;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
}

.languageContainer-Auth {
    width: 350px;
    position: absolute;
    top: 15px;
}

@media (prefers-color-scheme: dark) {
    .authGetStartedParagraph {
        color: #f4f4f4;
    }

}

@media only screen and (max-width: 480px) {
    .languageContainer-Auth {
        position: relative;
    }

    .authGetStartedPage {
        background-color: #ffffff;
    }

    @media (prefers-color-scheme: dark) {
        .authGetStartedPage {
            background-color: #121212;
        }
    }

    .authGetStartedHeader {
        display: grid;
        height: 10vh;
        justify-content: center;
    }

    .authGetStartedMainGrid {
        width: 100vw;
        max-height: 100vh;
        position: relative;
        border-radius: 0px;
        top: 0px;
        bottom: 0px;
        box-shadow: none;
    }

    .authGetStartedContainer {
        background-color: var(--bg-content-color);
    }
}