.clickPopoverMainClass {
    --width: 90vw;
    --max-width: 700px;
    --height: fit-content;
    --backdrop-opacity: 0.8;
}
.clickPopoverMainClass::part(content) {
    top: calc(44px + var(--offset-y, 0px))!important;
}

.clickPopoverMainClass >.ion-delegate-host{
    max-height: 75vh;
    color: #fff;
}

.clickPopoverMainClass .popover-viewport {
    display: contents;
}


.clickPopoverMainClass .popover-content {
    position: absolute;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    margin: auto !important;

}

.clickPopoverInnerDiv {
    margin: 10px;
    overflow-y: scroll;
}

.clickPopoverHeaderParagraph {
    font-size: x-large;
    text-align: center;
    font-weight: bold;
    color: var(--txt-color);
}

.clickPopoverTextDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    overflow-y: scroll !important;
}

.clickPopoverTextElement {
    width: fit-content;

    border-radius: 18px;
    overflow: hidden;

    color: #000;
    /* background-color: var(--accents-color); */

    margin: 0;
    margin-right: 15px;
    margin-bottom: 10px;
    padding: 8px;

    cursor: pointer !important;

    font-size: x-large;
}

.clickPopoverWordActionsDiv {
    padding: 30px;
    color: var(--txt-color);
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.clickPopoverWordDictionaryDiv {
    margin-top: 25px;
    padding: 10px;
}

.clickPopoverLettersSelectorDiv {
    margin-top: 50px;

    pointer-events: visible;

    text-align: center;
    padding: 15px;
    padding-bottom: 10px;
    border-radius: 6px;
}