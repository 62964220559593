.wordActionsSearchWordImagesDiv {
    position: relative;
    height: 200px;

    overflow-x: scroll;
    overflow-y: hidden;

    white-space: nowrap;
}

.spostamento {
    transform: translateX(-20px);
}

.wordActionImagesRow {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
    min-width: 150px;
    overflow-y: hidden;
    width: 100%;

}

.wordActionsImage {
    display: inline-block;
    overflow-y: hidden;
    /* height: 90%;
    width: 60%; */
    width:200px;
    margin-left: 5px;
    margin-right: 5px;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 1;
    border: 2px solid grey;
    border-radius: 15px;
}
.deleteImageIcon{
    position: absolute;
    width: 32px;
    height: 32px;
    margin-top: 5px;
    right: 10px;
    top: 0px;
    z-index: 10000;
    cursor: pointer;
    background-color: red;
    border-radius: 100%;

}
.imageContainer{
    width: 100%;
    height: max-content;
    position: relative;
}

.wordActionsWordParagraph {
    text-align: left;
}

.wordActionsActionButton {
    --background: var(--callToAction-color);
}

@media (prefers-color-scheme: light) {

    .invert {
        filter: invert(100%);
    }
}



.wordActionsActionParagraph {
    padding-left: 10px;

}