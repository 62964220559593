@import url("../../../assets/colorsCSS/colorPalettes.css");



.body {
    background-color: var(--bg-page-color);
    opacity: 1;
}

.profileHeader {
    font-size: 50px;
    color: var(--txt-color);
    
}

.profileHeaderTablet {
    font-size: 50px;
    color: #fff;
    font-family: nunito;
}


.languageContainer {
    width: 200px;
    position: fixed;
    right: 0;
    top: 0;
}

.settingsDocumentViewingItemDiv {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.languageDocumentViewingItemDiv {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.appLanguageSelect {
    background-position: left;
    /* min-width: 70%; */
    padding-left: 0;
    width: 40%;
    text-align: center;
}


.lnIcon {
    color: var(--txt-color);
    width: 20px;
    height: 20px;
}


.appLanguageSelect::part(text) {
    color: var(--txt-color);
    font-weight: 600;
    font-weight: bold !important;
    font-family: Nunito;
    opacity: 1;
}

.appLanguageSelect::part(icon) {
    padding-left: 15px;
    width: 18px;
    height: 18px;
}

.ionItems {
    --background: none;
}





/* ion-item .profileIcon{
    content:url("../../../assets/images/person.svg")
}
.profileItem{
    color:#3626A7;
    font-weight: 600;
}  */

.title {
    font-size: 25px;
    font-weight: 600;
    color: var(--txt-color)
}

.grid {
    width: 100%;
    padding-left: 100px;
    padding-top: 100px;
    overflow-y: scroll;
    /* transition: margin-left .5s; */
}


.cardBg {
    --background: transparent;
    box-shadow: none;
}

.profileUserAmbassadorPar{
    width: fit-content;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.redirectIcon{
    margin-left: 5px;
    font-size: 15px;
}
.reasyAmbassadorIcon{
    font-size: 32px;
    margin-right: 10px;
}

.profileUserDataRow {
    flex: 1;
    margin-bottom: 10px;
    color: var(--txt-color)
}

.profileLogoutDiv {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    max-width: 300px;
    padding-inline-start: 20px; /* used in order to match the ion-card padding */
    flex-direction: column;
}

.profileLogoutDivTablet {
    display: flex;
    max-width: 100%;
    flex-direction: column;
}

.provileLogoutDivItemTablet {
    --background: transparent;
    --inner-border-width: 0px 0px 3px 0px;
    position: relative;
    left: -21px;
}
.profileSettingsItem{
    --background: var(--bg-page-color);
    --padding: 0;
    color:var(--txt-color)
}
.profileSettingsItem:hover{
    --color: var(--neutral-color);
}
.logoutBtn,
.profileDeleteAccountBtn,
.profilePurchaseAccountBtn,
.profileManageAccountBtn {
    /* color: var(--txt-color);
    display: flex;
    --box-shadow: none;
    font-size: 17px;
    font-weight: 600;
    height: 44px;
    align-items: center;
    border-radius: 29px;
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 40px; */
    background: transparent;
        color: var(--txt-color);
        display: flex;
        --box-shadow: none;
        justify-content: space-between;
        width: -webkit-fill-available;
        font-size: 17px;
        font-weight: 600;
        height: 44px;
        align-items: center;
        text-transform: capitalize;
        border: none;
}

.logoutBtn {
    /* background: var(--callToAction-color); */
    width: 223px;
    grid-area: 1 / 1 / 2 / 2;
}

.profileDeleteAccountBtn {
    background: transparent;
    border: 2px solid var(--neutral-color);
    width: 223px;
    color: var(--neutral-color);
    grid-area: 1 / 2 / 2 / 3;

}

/* .profilePurchaseAccountBtn {
    background: transparent;
    border: 2px solid var(--neutral-color);
    width: 223px;
    color: var(--neutral-color);
    grid-area: 2 / 1 / 3 / 2;
} */

/* .profileManageAccountBtn {
    background: transparent;
    border: 2px solid var(--neutral-color);
    width: 223px;
    color: var(--neutral-color);
    grid-area: 2 / 2 / 3 / 3;
} */


.profileSupportGrid {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    color: var(--txt-color)
}

.profileSupportRow {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    cursor: pointer;
}

.profileSupportIcon {
    flex: 1;
    color: var(--txt-color);
    width: 14px;
    height: 18px;
}

.profileSupportLabel {
    flex: 5;
    font-size: 17px;
}

.btnIcon {
    /* display: none; */
}

#organizationSection {
    display: none;
}
.profileSubscriptionButton{
    text-transform: none;
    color: var(--txt-color);
}
.profileSubscriptionButton >p{
    padding-left: 15px;
}
#ogImg {
    /* background: transparent url('../../../assets/icons/school.png') 0% 0% no-repeat padding-box; */
    opacity: 1;
    height: 90px;
    margin: 32px 0 36px 0;
    background-size: contain;
    max-width: 150px;
}

.footer {
    display: none;
}


.mobileHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--bg-content-color);
    box-shadow: 0px 0px 20px 1px #0003;
    padding: 10px;
    height: 9vh;
    text-align: center;
}
.profileRedeemLicenseItem >.detail-icon{
    display: none;
}
.profileRedeemLicenseItem{
    --background: var(--background);
    color: var(--txt-color);
}
.lockIconLicense{
    color: var(--txt-color);
}
.accountDeletionPopover {
    --width: 34.5rem;
    background: var(--bg-content-color);
    color: var(--txt-color)
}

.accountDeletionPopoverDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 25px;
}

.accountDeletionPopover {
    display: contents;
    height: 24rem;
    background: var(--bg-content-color);
    color: var(--txt-color);
}

.removeAccountBtn {
    --background: rgba(161, 161, 161, 0.523) !important;
    --border-width: 10px;
    --border-color: #919191;
    --border-radius: 29px;

    width: 145px;
    color: #ffffff !important;
}

.removeAccountCancelBtn {
    background: var(--callToAction-color);
}

.removeAccountBtn,
.removeAccountCancelBtn {
    width: 145px;
    --background: transparent;
    color: #f1f1f1;
    display: flex;
    --box-shadow: none;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
    height: 44px;
    align-items: center;
    border-radius: 29px;
    text-transform: uppercase;
}

.removeAccountBtn::part(native):hover,
.removeAccountCancelBtn::part(native):hover {
    border-radius: 100px;
}

.accountDeletionPopoverText {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    color: var(--txt-color);
}

.accountDeletionPopoverText ion-text:nth-child(1) {
    font-size: 25px;
}
.mobileRedeemBookAccordion{
    border-radius: 15px;
    background: rgb(143, 160, 255);
}
.mobileRedeemBookItem{
    --background-activated:transparent;
    --ripple-color:transparent;
    --background: rgb(143, 160, 255);

}
.mobileRedeemBookContentDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background: rgb(143, 160, 255);
}
.mobileRedeemBookInput {
    --highlight-color-focused:rgb(122, 122, 255);
    --padding-start:15px !important;
    font-size: 15px;
    width: 100%;
    color: var(--txt-color);
}
.mobileRedeemBookErrorLabel{
    font-size: 14px;
    color: red;
}
.mobileRedeemBookConfirmation{
    font-size: 14px;
    color: rgb(43, 233, 69);
}
.mobileRedeemBookPopoverButton{
    align-self: center;
    font-size: 14px;
    --height: 10px;
    --background: red;
}
.invertDefaultImageDarkMode{
    filter: none;
}

.profileLicensesGrid{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    max-width: 85%;
    color: var(--txt-color)
}
.profileLicenseRow{
    min-width: 20%;
}
.profileLicenseCard{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px;
    border: 2px solid var(--accents-color);
    border-radius: 15px;
}
.profileLicenseCardHeader{
    font-size: larger;
    font-weight: 700;
    color:var(--txt-color)

}


@media only screen and (max-width: 869px) {
    .sideMenuBtn {
        display: none;
    }

    .grid {
        margin: 0 0 0 0;
        overflow-x: auto;
        padding-left: 0 !important;
        padding-top: 0 !important;
    }

    .languageContainer {
        position: inherit;
        height: 50px;
        margin: 0 0 0 10px;
    }

    .languageContent,
    .ionItems {
        padding: 0;
        height: 50px;
    }

    .settingsDocumentViewingItemDiv {
        margin: 0 !important;
    }

    .appLanguageSelect {
        padding: 0;
    }


    .profileHeader {
        position: sticky;
        font-size: 30px;
        text-align: center;
        font-weight: 600;
        height: 66px;
    }

    .profileLogoutDiv {
        display: flex;
        flex-direction: column;
        grid-row-gap: 0;
    }

    .logoutBtn,
    .profileDeleteAccountBtn,
    .profileManageAccountBtn,
    .profilePurchaseAccountBtn {
        background: transparent;
        color: var(--txt-color);
        display: flex;
        --box-shadow: none;
        justify-content: space-between;
        width: -webkit-fill-available;
        font-size: 17px;
        font-weight: 600;
        height: 44px;
        align-items: center;
        text-transform: capitalize;
        border: none;
    }

    .btnIcon {
        display: block;
    }

    .ionicon-fill-none {
        --fill: currentColor;
    }

    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 90%;
    }
}

@media only screen and (max-width: 480px) {


    .sideMenuBtn {
        display: none;
    }

    .languageContainer {
        position: inherit;
    }
    .languageContent{
        align-items: flex-start !important;
    }
    .languageContent,
    .ionItems {
        padding: 0;
        height: 50px;
    }

    .settingsDocumentViewingItemDiv {
        margin: 0 !important;
    }

    .appLanguageSelect {
        padding: 0;
    }

    .profileHeader {
        position: sticky;
        font-size: 17px;
        text-align: center;
        font-weight: 600;
        height: 20px;
    }

    .profileHeaderTablet {
        position: sticky;
        font-size: 17px;
        text-align: center;
        font-weight: 600;
        height: 20px;
        color: #fff;
    }



    .profileLogoutDiv {
        display: flex;
        flex-direction: column;
        align-items: start;
        grid-row-gap: 0;
    }
    .logoutBtn,
    .profileDeleteAccountBtn,
    .profileManageAccountBtn,
    .profilePurchaseAccountBtn {
        background: transparent;
        color: var(--txt-color);
        display: flex;
        --box-shadow: none;
        justify-content: space-between;
        width: -webkit-fill-available;
        font-size: 17px;
        font-weight: 600;
        height: 44px;
        align-items: center;
        text-transform: capitalize;
        border: none;
    }

    .btnIcon {
        display: block;
    }

    .ionicon-fill-none {
        --fill: currentColor;
    }

    .accountDeletionPopover {
        --width: 21rem;
    }
    .profileLicensesGrid{
        max-width: 90vw;
        max-height: 200px;
        flex-direction: column;
    }
}

.mobileHeaderToolbarHome {
    --background: transparent;
}

@media (prefers-color-scheme: dark) {
    .mobileRedeemBookInput{
    }
    .invertDefaultImageDarkMode{
        filter: invert(100%);
    }
}