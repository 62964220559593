.paywallPayOuterDiv {
    z-index: 1000;
    height: 100%;
    width: 100%;

    background-color: rgba(0, 0, 0, 0.7);

    display: flex;
    justify-content: center;
    align-items: center;
}

.paywallPayInnerDiv {
    flex: 1;
    
    width: 60%;
    max-width: 500px;
    
    max-height: fit-content;

    background-color: var(--bg-page-color);
    border-radius: 10px;
    overflow: hidden;

    flex-direction: column;
    justify-content: space-between;

    padding: 10px;
    margin: 20px;
}


.paywallPayContentDiv {
    margin: 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
    color:var(--txt-color);
}

.paywallLicenseButtonDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.paywallLicenseButton {
    --background: #FF331F;
    width: 100%;
    margin: 0;
}
.paywallTrialButton {
    --background: transparent;
    border: 2px solid #919191;
    color: #919191;
    white-space: normal;
    --box-shadow:none;
    margin-top: 15px;
    font-size: 12px;
}
.paywallTrialButton::part(native) {
    line-height: 1.6;
}
.paywallTrialButton:hover {
    --background-hover:var(--hover-color);
}
.paywallReloadButton{
    text-decoration: none;
    width: fit-content;
    font-size: 12px;
    margin-top: 5px;
}
.paywallReloadButton:hover{
    opacity: 0.7;
    cursor: pointer;
}
.inputField{
    background-color: #d3d3d3;
    max-height: 45px;
    color: #222222;
    text-align: center;
}

.redeemAreaDiv{
    margin: 10px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 225px;
    align-items: center;
    color:var(--txt-color);
}

.warning{
    color:#FF331F;
}

.summaryPopoverOkTxt ion-text:nth-child(1){
    text-align: center;
    font-size: 25px;
}
.summaryPopoverOkTxt ion-text:nth-child(2){
    text-align: center;
    margin-top: 8px;
    font-size: 17px;
}
.paywallContactUsButtonDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}
.contactSup{
    --background: transparent;
    --color: var(--txt-color);
    font-size: 12px;

}


  @media only screen and (max-width: 480px) {

    .paywallTrialButton {
        font-size: 14px;
    }
  }