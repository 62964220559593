.authChoiceIllustrationDiv {
    flex: 2;
    max-height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.authChoiceButtonsDiv {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 29vh;
}

.authChoiceSignUpButton {
    text-transform: uppercase;
    width: 225px;
    height: 44px;
    /* UI Properties */
    color: #ffffff;
    --background: #ff331f ;
    --border-radius: 29px ;
    opacity: 1;
    margin-bottom: 5vh;
    margin-top: 15px;
}

.authChoiceSignUpButton:hover{
    --background-activated: #f95e50 ;
    --background-hover: #f95e50 ;

}

.authChoiceSignInButton {
    text-transform: uppercase;
    width: 225px;
    height: 44px;
    /* UI Properties */
    color: #ffffff;
    border-radius: 29px;
    opacity: 1;
    margin-bottom: 5vh;
    --border-width: 2px;
    --border-color: black;
}

@media only screen and (max-width: 480px) {
    .authChoiceIllustrationDiv {
        flex: 2;
        height: 20vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .authChoiceButtonsDiv {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
/* 
    .authChoiceSignUpButton {
        text-transform: uppercase;
        width: 225px;
        height: 44px;
        --background-color: #ff331f !important;
        color: #ffffff;
        --border-radius: 29px !important;
        opacity: 1;
        margin-bottom: 5vh;
    } */

    .authChoiceSignInButton {
        text-transform: uppercase;
        width: 225px;
        height: 44px;
        /* UI Properties */
        color: #ffffff;
        border-radius: 29px;
        opacity: 1;
        margin-bottom: 5vh;
        --border-width: 2px;
        --border-color: black;
    }
}