.summaryViewerContent {
    --background: var(--bg-page-color);
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
}
.summaryViewerContainer{
    background: var(--bg-page-color);
    overflow-x: auto;
    overflow-y: hidden;
}
.summaryViewerContentContainer {
    margin-top: clamp(100px, 12vh, 300px);
  }
  
  .summaryViewerContentContainer-expanded {
    margin-top: clamp(110px, 12vh, 300px);
  }
  @supports (-webkit-touch-callout: none) {
    .summaryViewerContentContainer-expanded {
      margin-top: clamp(200px, 15vh, 350px);
    }
  }
.summaryViewerHeader{
    box-shadow: none!important;
    position: fixed;
    -webkit-box-shadow:none
}
.summaryViewerToolbar{
    max-width: 75%;
    margin: auto;
    --background:var(--bg-content-color);
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 5px 6px 3px #00000038;
}
.summaryViewerToolbarBtns{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.summaryViewerToolbarBtn{
  --color:var(--txt-color)
}
.page {
    font-family: Arial, Helvetica, sans-serif;
    padding: 20px;
    margin: 10px auto;
    background-color: var(--bg-content-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px var(--shadow-color);
    max-width: 800px; /* Limit max width for readability */
}
.page span{
    font-size: inherit;
}
.colored span{
    color:inherit;
}
h1 {
    font-size: 2em;
    color: var(--txt-color);
    margin: 0.5em 0;
}

h2 {
    font-size: 1.5em;
    color: var(--txt-color);
    margin: 0.5em 0;
}

p, li {
    font-size: 1em;
    color: var(--txt-color);
    line-height: 1.5; /* Improve readability */
    margin: 0.5em 0;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.ion-toolbar {
    background-color: white; /* Toolbar background */
    border-bottom: 1px solid var(--border-color);
    box-shadow: 0 2px 4px var(--shadow-color);
}

.ion-button {
    background-color: var(--btn-color);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    transition: background-color 0.3s ease; /* Smooth transition */
}

.ion-button:hover {
    background-color: var(--btn-hover-color); /* Change color on hover */
}

.color-picker-container {
    position: absolute; /* Positioning for color picker */
    z-index: 1000; /* Ensure it is above other elements */
    background-color: white;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 4px var(--shadow-color);
}

/* Styling for the color picker buttons */
.color-picker-button {
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 50%;
    transition: transform 0.3s; /* Scaling effect */
}

.color-picker-button:hover {
    transform: scale(1.1); /* Scale on hover */
}
.highlight {
    background-color: yellow;
    color: red;
    transition: background-color 0.2s, color 0.2s;
  }
  .ttsButtons {
    display: flex;
    justify-content: center;
    background-color: var(--bg-content-color);
    padding: 8px 12px;
    border-radius: 0 0 10px 10px;
    width: fit-content;
    margin: 0 auto;
    box-shadow: 0px 5px 6px 3px #00000038;
  }
  
  .ttsButtons button:hover {
    color: var(--txt-color);
  }
  
  .ttsButtons button:disabled {
    color: #6c6c6c;
    cursor: not-allowed;
  }
  
  .ttsButtons button:focus {
    outline: none;
  }

  .controls-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .select-controls {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-left: 10px;
  }
  
  .button-controls {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  /* Ionic Select customization */
  .ttsButtons ion-select {
    --placeholder-color: var(--txt-color);
    --color: var(--txt-color);
    background: transparent;
    /* padding: 4px; */
    border-radius: 4px;
    font-size: 14px;
  }
  
  .speed-select {
    max-width: 55px;
  }
  
  .quality-select {
    width: 70px;
  }
  
  /* Button styles */
  .starting-point-btn  {
    background-color: #4ecdc4 ;
    color: var(--txt-color) ;
    padding: 4px 8px ;
    border-radius: 15px;
    font-size: 12px;
    margin-left: 4px;
    display: flex;
    align-items: center;
  }
  .starting-point-btn .text{
    font-size: 12px;
  }
  .ttsButtons .controlls {
    background-color: transparent;
    border: none;
    color: var(--txt-color);
    padding: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
  }
  
  .ttsButtons ion-icon {
    font-size: 20px;
  }
  

  
  /* Mobile responsive styles */
  @media screen and (max-width: 480px) {
    .ttsButtons {
      padding: 6px 8px;
    }
  
    .controls-wrapper {
      gap: 8px;
    }
  
    .select-controls {
      gap: 4px;
    }
  
    .button-controls {
      gap: 2px;
    }
  
    .ttsButtons button {
      padding: 4px;
    }
  
    .ttsButtons ion-icon {
      font-size: 18px;
    }
  
    .starting-point-btn .text {
      display: none;
    }
  
    .starting-point-btn .icon {
      display: block;
    }
  
    .speed-select {
      width: 50px;
    }
  
    .quality-select {
      width: 80px;
    }
  }

  .selectionPopoverOuterDiv {
    z-index: 1000;
    transition: opacity 0.3s ease;
    scrollbar-width: thin;
    scrollbar-color: var(--accents-color) transparent;
}

.selectionPopoverOuterDiv::-webkit-scrollbar {
    width: 6px;
}

.selectionPopoverOuterDiv::-webkit-scrollbar-track {
    background: transparent;
}

.selectionPopoverOuterDiv::-webkit-scrollbar-thumb {
    background-color: var(--accents-color);
    border-radius: 3px;
}

.selectionPopoverInnerDiv {
    background: var(--bg-content-color);
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}


.dictonaryPopover {
    overflow-y: auto;
    max-height: calc(100vh - 40px);
}
  
  /* Media query for smaller screens */
  @media (max-width: 400px) {
    .ttsButtons button:last-child {
      padding: 6px;
      font-size: 16px;
      width: auto;
    }
    
    .ttsButtons button:last-child .text {
      display: none;
    }
    
    .ttsButtons button:last-child .icon {
      display: inline;
    }
  }
