@import '../../../assets/colorsCSS/colorPalettes.css';



.fileViewerTextGrid {
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    transition: all 0.6s;
    z-index: 10;
}

.textgridmobile {
    max-width: 900px;
    margin: 0;
    padding: 0;
}

.textgridnotmobile {

    padding-left: 100px;
    padding-right: 100px;
    max-width: 1100px;
}

.highlighted {
    background-color: red;
}
.back-btn{
    --background:transparent;
    --box-shadow:none;
    position: absolute;
    z-index: 1000000;
}
.back-btn-icon{
    font-size: 2rem;
    color:var(--txt-color)
}
.fileViewerContent {
    --background: var(--bg-page-color);
}

.fileViewerTextSelectable {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    color: var(--txt-color);
    padding: 20px;
}

.tickIcon::slotted(icon-inner) {
    color: red;
}

.spostaTesto {
    transform: translateX(-10%);
}

.fileViewerSelectedSectionsDiv {
    display: flex;
    width: fit-content;
    flex-direction: column;
    position: fixed;
    margin-left: 85%;
    top: 40%;
    text-align: center;
    color: var(--selection-txt-color);
}

.fileViewerMobileFooter {
    position: fixed;
    width: 100vw;
    bottom: 0px;
}

.fileViewerSelectedSectionsButton {
    background-color: green;
    --color: white !important;
    text-transform: none;
    border-radius: 15px;
}

.fileViewerProcessingAnimationDiv {
    padding: 20px;
}

.fileViewerProcessingAnimationParagraph {
    text-align: center;
    color: var(--txt-color);
}

.fileViewerPageDiv {
    flex: 1;
    white-space: pre-wrap;
}

.fileViewerTextarea {
    font-size: 18px;
    font-weight: bold;
    border: none;
    outline: none;
    height: fit-content;
    resize: none;
}

.fileViewerTutorialToast {
    --border-radius: 50px;
    --border-width: 3px;
    --border-style: solid;
    --border-color: #000000;
    --background: #1d69c4;
    opacity: 0.9;
    text-align: center;
    font-weight: bold;
    font-size: large;

}

.fileViewerAudioPlayer {
    display: flex;
    width: 300px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--bg-content-color);
    color: var(--txt-color);
}
.fileViewerAudioPlayerButtonsDiv{
    display: flex;
    flex-direction: row;
}
.ttsControlls {
    width: 100%;
    background: transparent;
}

.ttsControllsItem::part(native) {
    --border-color: transparent;
    background: transparent;
    color: var(--txt-color);
}
.ttsControlls.list-md {
    --border-color: transparent;
    background: transparent;
    color: var(--txt-color);
  }

.fileViewerParagraph[contenteditable]:focus {
    outline: 3px dashed var(--accents-color);
}
.ttsButtonsOld{
    font-size: 25px;
}

.ttsButtonsOld::part(native) {
    color: var(--callToAction-color);
}

.fileViewerImageAlert {
    --backdrop-opacity: 1;
    --max-width: 600px;
    --max-height: 600px;
}

#ModifyLabel,
#ttslabel,
#mapLabel,
#SummaryLabel,
#keyWordsLabel,
#markers {
    display: none;
    align-content: center;
    margin-left: 5px;
    transition: all 0.3s;
    color: var(--txt-color);
    pointer-events: none;
}

#markers {
    height: fit-content;
    padding: 10px;
    background-color: var(--bg-content-color);
    position: absolute;
    top: 25%;
    left: 100%;

}

.spotlightWordAdd-Btn,
.spotlightWordSave-Btn {
    --background: var(--callToAction-color);
    --border-radius: 30px;
}

.spotlightWordRemove-Btn {
    --background: transparent;
    color: var(--txt-color);
    border: 1px solid var(--neutral-color);
    --border-radius: 30px;
    border-radius: 30px;
}

.chrome-picker {
    background: transparent !important;
    box-shadow: none !important;
}

input {
    background: white;
}

#ttsbox {
    position: absolute;
    border-radius: 10px;
    right: 15px;
    display: inline-flex;
    width: 330px;
    box-shadow: 0px 10px 28px -6px rgba(0, 0, 0, 0.75);
    transition: all 0.5s;
}


@media only screen and (max-device-width: 480px) {
    .fileViewerImageAlert {
        --max-width: 200px;
        --max-height: 200px;
    }
}

.fileViewerTutorialDiv {
    position: absolute;
    top: 20vh;
    left: 10vh;
}

.main,
.menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-content-color);
    padding-right: 80px;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: 0.5s;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 8px 15px rgba(0, 0, 0, .2);
}

.menu {
    margin: 0;
    padding: 0;
    width: 0;
    overflow: hidden;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 15px; */
}

/* .main input:checked~.menu {
    width: 70vw;
}

.menu li {
    list-style: none;
    margin: 0 3px;
}


.menu li {
    text-decoration: none;
    color: #666;
    text-transform: uppercase;
    font-weight: 600;
    transition: 0.5s;
    z-index: -1000;
}

.menu li:hover + .actionButtons {
    color: #161919;
    z-index: 1000;
}

.menu .actionIconResize{
 color:var(--txt-color);
 z-index:1000;
}

.main input {
    position: absolute;
    right: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    opacity: 0;
} */

/* Inizio Tablet */

.selectionIonFabMain {
    padding: 15px;
}

.ViewerModalSelectionFunctionalityIcon {
    position: absolute;
    right: 20px;
    font-size: 1.3em;
}

.menuIconT {
    width: 5vh;
    height: 5vh;
    color: #27c309;
}



.ViewerModalSelectionFunctionalityDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

}

.ViewerModalSelectionFunctionality .ion-page {
    justify-content: space-between;
}


@media screen and (orientation: landscape) and (max-height: 1000px) and (min-width:1000) {
    .ViewerModalSelectionFunctionality {
        --height: 25vh;
    }
}

@media screen and (orientation: landscape) and (max-height: 1000px) and (min-height: 690px) {
    .ViewerModalSelectionFunctionality {
        --height: 28vh;
    }
}

@media screen and (orientation: landscape) and (max-height: 690px) {
    .ViewerModalSelectionFunctionality {
        --height: 33vh;
    }
}

@media screen and (orientation: portrait) and (min-height: 1080px) and (min-width: 821px) {
    .ViewerModalSelectionFunctionality {
        --height: 15vh;
    }
}

@media screen and (orientation: portrait) and (min-height: 1919px) and (min-width: 821px) {
    .ViewerModalSelectionFunctionality {
        --height: 11vh;
    }
}

@media screen and (max-width:512px) {
    .containerMobile {
        padding: 0;
    }

}

@media screen and (max-width:869px) {
    .containerMobile {
        padding: 0;
    }

}


/* Fine Tablet */

/* Inizo Mobile */
.menuIconM {
    width: 6vh;
    height: 6vh;
    color: #27c309;
}



.ViewerModalSelectionFunctionality {
    --width: 100%;
    --height: 28vh;
    --border-radius: 30px 30px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    --background: var(--bg-content-color);
}

.ViewerModalSelectionFunctionalityTitle {
    font-size: 5vw;
    font-family: Nunito;
    text-align: center;
    --background: transparent;
    color: var(--txt-color);
    width: 100%;
}

.selectionItemFunctionality {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 2.5vw;
    --ion-font-family: 'Nunito';
    --background: transparent;
    color: var(--txt-color);
    width: 100%
}

.selectionIconFunctionality {
    font-size: 5vw;
    padding-right: 5px;
}

.functionModalGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 3vh;
    margin-bottom: 5vh;
}

#div1 {
    grid-area: 1 / 1 / 2 / 2;
}

#div2 {
    grid-area: 1 / 2 / 2 / 3;
}

#div3 {
    grid-area: 1 / 3 / 2 / 4;
}

#div4 {
    grid-area: 2 / 1 / 3 / 2;
}

#div5 {
    grid-area: 2 / 2 / 3 / 3;
}

#div {
    grid-area: 2 / 3 / 3 / 4;
}


.selectionItemFunctionality .sc-ion-label-ios-h {
    white-space: pre-wrap;
    margin-right: 0;
    width: 100%;
}

.colorPicker>.ion-delegate-host {
    justify-content: center;
}

@media screen and (orientation: landscape) {
    .menuIconM {
        width: 12vh;
        height: 12vh;

    }

    .selectionItemFunctionality {
        font-size: 2vw;
    }

    .textgridmobile {
        max-width: 100%;
    }

    .ViewerModalSelectionFunctionality {
        --width: 80%;
        --height: 30vh;
        --border-radius: 30px;
        justify-content: center;
    }

    .selectionIconFunctionality {
        font-size: 4.07vw;

    }

    .ViewerModalSelectionFunctionalityTitle {
        font-size: 3.05vw !important;

    }


}

@media screen and (min-height: 600px) and (max-height: 790px) and (max-width: 390px) {
    .ViewerModalSelectionFunctionality {
        --height: 30vh;
    }
}


/* Fine Mobile*/

.actionIcon {
    font-size: 30px;
    margin-right: 15px;

}

.actionIconResize {
    scale: 0.7;
}

/* .main input:checked~ion-icon {
    background-color: #FF4D50;
} */


/* main span:nth-child(2) {
    transform: translateY(-8px);
}

.main input:checked~span:nth-child(2) {
    transform: translateY(0) rotate(-45deg);
}

.main span:nth-child(3) {
    transform: translateY(8px);
}
.main input:checked~span:nth-child(3) {
    transform: translateY(0) rotate(45deg);
}


.main input:checked~span:nth-child(4) {
    display: none;
}*/
ion-header ion-toolbar:first-of-type {
    padding-top: var(--ion-safe-area-top, 0);
    --min-height: 48px;
}

.actionButtons {
    position: fixed;
    display: grid;
    left: 53px;
    top: 161px;
    justify-content: space-between;
    height: 180px;
    width: 44px;
    z-index: 1000;
}

.actionButtons:hover {
    z-index: 1000;
}

.zoomButtons {
    display: grid;
    left: 45px;
    top: 430px;
    justify-content: space-between;
    width: 30px;
    color: var(--fileView-btn-color)
}

ion-buttons::native(part) {
    width: 35px;
}

.downloadButtonSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: var(--fileView-btn);
    height: 40px;
    position: fixed;
    width: 40px;
    top: 520px;
    left: 53px;
    z-index: 1000;
    display: block center;
    box-shadow: 0 8px 15px rgba(0, 0, 0, .2);
}

.downloadButton {
    height: 100% !important;
    width: 100% !important;
    top: 430px;
    justify-content: space-between;
    width: 30px;
    color: var(--fileView-btn-color)
}

.divZoom {
    border-radius: 5px;
    background-color: var(--fileView-btn);
    height: 65px;
    position: fixed;
    width: 40px;
    top: 440px;
    left: 53px;
    z-index: 1000;
    display: block center;
    box-shadow: 0 8px 15px rgba(0, 0, 0, .2);
}

.divNextPrevious {
    border-radius: 5px;
    background-color: var(--fileView-btn);
    height: 90px;
    position: fixed;
    width: 40px;
    top: 620px;
    left: 53px;
    z-index: 1000;
    display: block center;
    box-shadow: 0 8px 15px rgba(0, 0, 0, .2);
}

.btn-fileviewer {
    margin-top: 3px;
    width: 36px;
    color: white;
}

:root {
    --ion-color-whitebuttons: #ffffff;
    --ion-color-whitebuttons-rgb: 255, 255, 255;
    --ion-color-whitebuttons-contrast: #000000;
    --ion-color-whitebuttons-contrast-rgb: 0, 0, 0;
    --ion-color-whitebuttons-shade: #e0e0e0;
    --ion-color-whitebuttons-tint: #ffffff;
}

.ion-color-whitebuttons {
    --ion-color-base: var(--ion-color-whitebuttons);
    --ion-color-base-rgb: var(--ion-color-whitebuttons-rgb);
    --ion-color-contrast: var(--ion-color-whitebuttons-contrast);
    --ion-color-contrast-rgb: var(--ion-color-whitebuttons-contrast-rgb);
    --ion-color-shade: var(--ion-color-whitebuttons-shade);
    --ion-color-tint: var(--ion-color-whitebuttons-tint);
}

#fileViewerTextDivContainer {
    box-shadow: 0 8px 15px rgba(0, 0, 0, .2);

}


.paragraphinReading {
    border: 1px solid;
    background-color: var(--accents-color);
    border-radius: 10px;
    color: var(--selection-txt-color);
}

.btn-fileviewer::part(native) {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
}

.sideactionbtn {
    height: 45px;
    width: max-content;
    display: flex;
    flex-direction: row;
}

ion-select::part(icon) {
    display: block;
}

.selezionato {
    border: 3px dotted blue;
}


.summaryPopover,
.summaryPopoverOk {
    --width: 32.5rem;
    --height:40% ;
}

.summaryPopoverDiv,
.summaryPopoverOkDiv {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 25px;
    height: 275px;
    color: var(--txt-color)
}

.summaryCardLabels {
    font-weight: 600;
    font-size: 18px;
}

.summaryLanguage::part(native) {
    background: transparent;
    border: 0;
    padding-left: 35px;
    color: var(--txt-color)
}

.sc-ion-select-popover-h ion-list.sc-ion-select-popover {
    background: var(--bg-content-color);
}

.select-interface-option::part(native) {
    color: var(--txt-color);
    background: transparent;
}


.sc-ion-select-popover::part(container) {
    border-color: var(--neutral-color);
}

.Ln-selection {
    background: transparent;
    border: 0;
}

.doSummary {
    width: 145px;
    --background: transparent;
    background-color: var(--btnColorRed);
    color: #f1f1f1;
    background: var(--callToAction-color);
}

.doSummary::part(native):hover,
.annullaSummary::part(native):hover {
    border-radius: 100px;
}

.annullaSummary {
    --background: transparent;
    --border-width: 2px;
    --border-color: #919191;
    --border-radius: 29px;

    width: 145px;
    color: #919191;
}

.doSummary,
.annullaSummary {

    display: flex;
    --box-shadow: none;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
    height: 44px;
    align-items: center;
    border-radius: 29px;
    text-transform: uppercase;
}

.summaryPopoverBtns {
    display: flex;
    justify-content: space-around;
    width: 360px;
}

.summaryLoadingIcon {
    width: 58px;
    height: 79px;
    filter: invert(var(--invertWB));
}

.summaryReadyIcon {
    width: 80px;
    height: 79px;

}

.summaryPopoverOkTxt {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.summaryPopoverOkTxt ion-text:nth-child(1) {
    font-size: 25px;
}

.summaryPopoverOkTxt ion-text:nth-child(2) {
    margin-top: 8px;
    font-size: 17px;
}

.keyWordsStyle {
    background-color: var(--accents-color);
    border-radius: 35px;
    padding: 0.3rem 1rem 0.3rem 1rem;
    font-size: 18px;
    margin: 10px;
    color: var(--selection-txt-color);
}

.keyWordsContainer {
    display: flex;
    flex-wrap: wrap;
}

.annullaMappa {
    color: var(--txt-color);
    --border-width: 2px;
    --border-color: var(--neutral-color);
    --border-radius: 29px;
}

.createMapButton {
    --background: var(--callToAction-color);
    --border-radius: 29px;
    color: var(--txt-color);
}

#fileViewerTextDivContainer {
    background: var(--bg-content-color);
}

.btn-fileviewer::part(native) {
    background: var(--fileView-btn);
    color: var(--fileView-btn-color);
}

:root.dark-mode {
    .fileViewerProcessingAnimationParagraph {
        /* color: #ffffff */
    }
}

.sumPopupTxt {
    font-size: 30px;
}


@media (prefers-color-scheme: dark) {


    .btn-fileviewer::part(native) {
        color: #121212;
    }

    .zoomButtons {
        color: #121212;
    }

    .downloadButton {
        color: #121212;
    }

    .btn-background {
        --background: red
    }

    .pdfReadyPopoverDiv {
        color: #f1f1f1
    }

}

@media(prefers-color-scheme: light) {


    .keyWordsStyle,
    .paragraphinReading {
        color: #fff;
    }
}

@media screen and (max-width:840px) {
    .helpIconModal {
        position: absolute;
        z-index: 100000;
        right: 20px;
        bottom: 20px;
        color: var(--txt-color);
    }

    .summaryPopover,
    .summaryPopoverOk {
        --width: 100%;
        --height: 32%;

    }
}

@media only screen and (max-width: 480px) {

    .summaryPopover,
    .summaryPopoverOk {
        --width: 94%;
        --height: 35%;
    }

    .summaryPopover>.ion-delegate-host,
    .summaryPopoverOk>.ion-delegate-host {
        overflow: hidden;
    }

    #ttsbox {
        bottom: 0;
        right: 0;
    }

    .functionModalGrid {
        grid-template-columns: repeat(2, 1fr);
        /* Change to 2 columns */
        grid-template-rows: repeat(3, 0.5fr);
        /* Change to 3 rows */
        grid-column-gap: 0;

    }

    #div1 {
        grid-area: 1 / 1 / 2 / 2;
    }

    #div2 {
        grid-area: 1 / 2 / 2 / 3;
    }

    #div3 {
        grid-area: 2 / 1 / 3 / 2;
    }

    #div4 {
        grid-area: 2 / 2 / 3 / 3;
    }

    #div5 {
        grid-area: 3 / 1 / 4 / 2;
    }

    #div6 {
        grid-area: 3 / 2 / 4 / 3;
        ;
    }

    .selectionItemFunctionality {
        font-size: 4vw;
    }

    .selectionIconFunctionality {
        font-size: 5vw;
    }

    .selectionItemFunctionality .sc-ion-label-ios-h {
        margin-right: 0;
    }

}

#paragraphWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

#addBtn {
    align-self: flex-end;
    position: absolute;
    right: 0;
    width: 45px;
    height: 45px;
}

#addBtn::part(native) {
    background: var(--positive-color);
    width: 48px;
    border-radius: 100px;
}

.addFirstParagraph {
    margin-left: 50%;
    transform: translateX(-50%);
}

.addFirstParagraph::part(native) {
    background: var(--positive-color);
}

#removeBtn {
    cursor: pointer;
    margin-right: 30px;
    height: 48px;
}

#removeBtn::part(native) {
    background: var(--callToAction-color);
    width: 48px;
    border-radius: 100px;
}

.addImage {
    font-size: 36px !important;
    color: #fff;
}

.removeImage {
    color: #fff;
    font-size: 36px !important;
}

.btn-background {
    --background: white
}

.mapInstructionsPopover {
    --width: 65vw;
    --height: 50%;
}

.mapInstructionsPopover .popover-viewport {
    display: contents;
}

.mapInstructionsPopoverDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 100%;
    font-size: 17px;
    font-weight: bold;
    color: var(--txt-color);
}

.squareSelector {
    width: 30px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-color: var(--txt-color);
}

.pdfReadyPopover .popover-content {
    width: 80vw;
    background: var(--bg-content-color);
    color: var(--txt-color)
}

.pdfReadyPopoverDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pdfReadyPopover {
    --backdrop-opacity: .6;
}

.pdfReadyPopover .popover-viewport {
    display: contents;
    height: 45vh;
    background: var(--bg-content-color);
    color: var(--txt-color);
}

.tickIcon {
    font-size: 30px;
    --ionicon-stroke-width: 70px;
}

.mapButtonMobileDiv {
    width: 100%;
    position: fixed;
    bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mapButtonMobileDiv>ion-button {
    width: 75vw;
}

.createMapButtonDiv {
    width: 100%;
    position: fixed;
    bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.createMapButtonDiv>ion-button {
    width: 75vw;
}

.spinner {
    position: fixed;
    color: var(--accents-color)
}

.bigBookLoader {
    position: absolute;
    width: 300px;
    height: 150px;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes fillAnimation {
    0% {
        clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
}
/* Keyframe animation for fading in */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  /* Animation class */
  .animated-element {
    animation: fadeIn 0.5s ease forwards; /* Adjust time and easing as needed */
  }

.fill-icon {
    display: inline-block;

    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    /* Initial clip path to make it invisible */
    animation: fillAnimation 2s ease-out infinite;
    /* Adjust the duration and timing function as needed */
    animation-fill-mode: forwards;
    /* Ensure the final state persists after the animation */
}

@media screen and (min-width: 480px) and (max-width: 869px) {
    #ttsbox {
        bottom: 0;
        left: 0;
    }
}

.spotlightWordSave-Btn {
    width: 100%;
}

.modalHighlightBtns {
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 0;
    background: var(--bg-content-color);
    z-index: 2;
    right: 0;
    text-align: center;
    align-content: center;
}

.underline {
    background-color: #ffff00;
    color: black;
}