.popup {
  position: fixed;
  background-color: var(--bg-content-color);
  border-radius: 12px;
  padding: 24px;
  width:  clamp(250px, 34vw, 320px);
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  user-select: none;
}

.move-btn {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: move;
  width: 24px;
  height: 24px;
  padding: 4px;
  background-color: #2a2a36;
  border-radius: 6px;
  color: #ffffff;
}

/* Slider track styles */
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  outline: none;
}

/* Thumb styles */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

/* Track styles for Firefox */
input[type="range"]::-moz-range-track {
  background: none;
  border: none;
}

/* Red slider */
input[type="range"].red-slider {
  background: linear-gradient(to right, rgb(0, 0, 0), rgb(255, 0, 0));
}

input[type="range"].red-slider::-webkit-slider-thumb {
  background: rgb(255, 0, 0);
}

input[type="range"].red-slider::-moz-range-thumb {
  background: rgb(255, 0, 0);
}

/* Green slider */
input[type="range"].green-slider {
  background: linear-gradient(to right, rgb(0, 0, 0), rgb(0, 255, 0));
}

input[type="range"].green-slider::-webkit-slider-thumb {
  background: rgb(0, 255, 0);
}

input[type="range"].green-slider::-moz-range-thumb {
  background: rgb(0, 255, 0);
}

/* Blue slider */
input[type="range"].blue-slider {
  background: linear-gradient(to right, rgb(0, 0, 0), rgb(0, 0, 255));
}

input[type="range"].blue-slider::-webkit-slider-thumb {
  background: rgb(0, 0, 255);
}

input[type="range"].blue-slider::-moz-range-thumb {
  background: rgb(0, 0, 255);
}
/* Labels and text */
label {
  color: var(--txt-color);
  font-size: 14px;
  font-weight: 500;
}

span {
  font-size: 14px;
}

/* Color preview
.color-preview {
  background-color: var(--preview-color);
  border: 2px solid #2a2a36;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

/* IonButton customization */
.summariesColorPicker {
  --background: #4a4a57;
  --background-hover: #5a5a67;
  --background-activated: #3a3a47;
  --border-radius: 8px;
  --color: #ffffff;
  --padding-top: 16px;
  --padding-bottom: 16px;
  font-weight: 500;
  text-transform: none;
  margin: 0;
}

/* Utility classes */
.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.mb-2 {
  margin-bottom: 8px;
}

.mr-2 {
  margin-right: 8px;
}

.ml-2 {
  margin-left: 8px;
}


.mt-4 {
  margin-top: 16px;
}

.w-10 {
  width: 40px;
  color: var(--txt-color);
}

.w-16 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h-16 {
  height: 45px;
}

.w-full {
  width: 100%;
  --background:#5559c0;
}

.rounded-md {
  border-radius: 8px;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace;
  color: white
}

.text-right {
  text-align: right;
}