@import url("../../../assets/colorsCSS/colorPalettes.css");
.body {
    background-color: var(--bg-page-color);
    opacity: 1;
}

.purchaseIosDiscountHeaderButton {
    font-size: x-large !important;
    margin-top: 6px !important;
}

.purchaseMainGrid {
    max-width: 90vw;
    height: 100%;
    font-size: 20px;

    padding: 10px;
    padding-bottom: 0;
}

.purchaseMainDiv {
    height: 99%;
    display: flex;
    flex-direction: column;

    text-align: center;
}

.purchaseTitleDiv {
   flex: 1;
   
   display: flex;
   flex-direction: row;
   align-items: center;
}

.purchaseTitleInnerDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.purchaseTitleParagraph {
    font-size: 1.3em;
    margin-bottom: 10px;
}
.purchaseCTAParagraph{
    font-size: 1.1em;
}
.purchaseDiscountCouponDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: lightgray;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 2px;
    overflow: hidden;

    color: black;
}

.purchaseDiscountCouponName {
    padding: 0;
    margin: 0;
    margin-left: 5px;
}

.purchaseSlides {
    height: 85%;
}

.purchaseDiscountCouponHintOnMobileDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: var(--ion-color-success-shade);
    margin-top: 5px;
    padding: 5px;
    border-radius: 2px;
    overflow: hidden;

    color: white;
    cursor: pointer;
}

.tableIcons{
    color:var(--txt-color)
}
.purchasePeriodToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%; /* Adjust based on your chip widths */
    height: 36px; /* Adjust based on your chip height */
}

  .purchasePeriodChip {
    font-size: .9em;
    margin: 0;
    border: 1px solid var(--txt-color);
    width: 160px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    background-color: transparent;
}

.yearlyChip {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0px;
}

.monthlyChip {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0px;
}
  
.selectedPeriod {
    color: white; /* Ensure text is visible on colored background */
}

.colorSlider {
    position: absolute;
    top: 0;
    width: 160px;
    height: 100%;
    background-color: #6797ff;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
    border-radius: 15px;
    transform: translateX(-50%);
}

.colorSliderMonthly {
    transform: translateX(50%);
}
.purchaseYearlyDiscountChip{
    background-color: rgb(184, 255, 184);
    color:rgb(0, 83, 0);
    border:1px solid rgb(0, 83, 0);
    border-radius: 10px;
    min-height: 20px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: .8em;


}
  
.purchaseBenefitsDiv {
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px; 
    padding-top: 20px;
    width: 90vw;
}

.purchaseProductContainer {
    display: flex;
    justify-content: center;
    gap: 50px; 
    font-size: 20px;
}

.purchaseProductInfoDiv {
    flex: 0 0 30vw;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 8px; 
    text-align: left;
    border: 1px solid #ccc; 
}
.productInfoDiv-0{
    background-color: #adffbb;
}
.productInfoDiv-1{
    background-color: #ffc89a;
}
.purchaseProductNameParagraph{
    font-size: 2em;
    margin-bottom: 10px;
    font-family: Nunito;
    text-align: center;
    margin-top: 10px;
}
.purchaseProductPriceParagraph{
    font-size: 3.5em;
    margin-top: 20px;
    margin-bottom: .6em;
    font-family: Nunito Bold;
    text-align: center;
}
.purchaseProductLimitInnerDiv{
    padding-left: 45px;
}
.purchaseProductLimitParagraph{
    font-size: .9em;

}
.purchaseProductLimitParagraph >b{
    font-family: Nunito Bold;
}

.purchaseProductButtonDiv{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}
.purchaseBenefitsDiv::-webkit-scrollbar {
    height: 8px;
}

.purchaseBenefitsDiv::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.purchaseBenefitsDiv::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}


.purchaseButtonsAndTermsDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.purchaseButton {
    text-transform: none;
    /* margin-bottom: 20px; */
}
.purchase-0{
    --background: #007c15;
}
.purchase-1{
    --background: #ff5e00;
}

.purchaseRestorePurchasesDiv {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: small;
    color: var(--txt-color);
    cursor: pointer;
    --background: transparent;
}

.purchaseTermsDiv {
    padding-left: 10px;
    padding-right: 10px;
    font-size: x-small;
    color: gray;
    text-align: center;

    margin-top: 10px;
    margin-bottom: 20px;
}

.purchaseTermsDiv > p > a {
    color: gray;
}

/* src/components/Table.css */
.custom-table {
    width: 75%;
    border-collapse: collapse;
    margin: auto;
}
  
.custom-table td {
padding: 8px;
text-align: left;

}
.custom-table td:nth-child(1) {
background-color: #8c8c8c;
font-size:30px;
}

.custom-table th {
background-color: #333;
color: white;
padding: 10px;
text-align: left;
text-transform: capitalize;
}


@media only screen and (max-width: 480px) {
    .purchaseTitleParagraph{
        font-size: .7em;
    }
    .purchaseCTAParagraph{
        font-size: .6em;
    }
    .purchasePeriodChip{
        font-size: .6em;
        width: 40vw;
    }
    .colorSlider{
        width: 40vw;
    }
    .purchaseBenefitsDiv{
        width: inherit;
    }
    .purchaseProductNameParagraph{
        font-size: 1em;
    }
    .purchaseProductPriceParagraph{
        font-size: 2em;
    }
    .purchaseProductLimitParagraph{
        font-size: .5em;
    }
    .purchaseProductContainer{
        flex-direction: column;
    }
}

@media (prefers-color-scheme:dark) {
    .purchaseProductInfoDiv{
        color:#0b1215;
    }
}