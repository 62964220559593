@import url("../../../assets/colorsCSS/colorPalettes.css");

.getStartedMainGrid {
    max-width: 500px;
    height: 100vh;

    display: flex;
    flex-direction: column;
}

.getStartedTextDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    padding-left: 10px;
    padding-right: 10px;
}

.getStartedTextInnerDiv {
    text-align: center;
}

.getStartedInputsDiv {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.getStartedLottieDiv {
    margin-bottom: 20px;
}

.getStartedInputField {
    background: #f8f4fc;
    text-align: center;
    overflow: hidden;
}

.getStartedLegalTermsDiv {
    margin-top: 5vh;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.getStartedLegalTermsParagraph {
    margin-left: 10px;
    color: gray;
    font-size: small;
}

.getStartedButtonsDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding-left: 10px;
    padding-right: 10px;
}

.getStartedButton {
    text-transform: none;
}

.getStartedResetPasswordButton {
    text-transform: none;
}

.authSupportHeaderToolbar {
    --background: #ffffff;
}

.authSupportHeader {}


.authSupportIcon {
    align-self: flex-end;
    font-size: 50px;
    position: absolute;
}

.loginLogo {
    width: 54%;
    margin-top: 30px;
}

@media (prefers-color-scheme:dark) {
    .authSupportHeader {
        background: #121212;
    }

    .authSupportHeaderToolbar {
        --background: #121212;
    }

    .authSupportIcon {
        color: #ffffff;
    }
}

.passwordShowBtn {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    position: absolute;
    z-index: 1000;
    right: 0;
    cursor: pointer;
}

.passEyeContainer {
    display: flex;
    margin-bottom: 3vh;
    align-items: center;
    position: relative;
}