.loadingMaterialMainDiv {
    padding: 10px;
    max-width: 80vw;
    width: 80vw;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* font-size: 20px; */
    overflow-y: scroll;

    /* HIDE SCROLLBAR */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

}

.loadingMaterialMainDiv::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}


.loadingMaterialHeaderDiv {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: .5em;
    /* margin-bottom: 3.5em; */
    /* height: 3.5em; */
}

.loadingMaterialHeaderPar {
    margin: 0;
    margin-bottom: .5em;
    padding: 0;
    font-size: clamp(1em,2vw,2em);
    text-align: center;
}

.loadingMaterialHeaderTitle {
    margin: 0;
    padding: 0;
    font-size:clamp(1em,3vw,2em);
}


.loadingMaterialMaterialsContainer{
    margin-top: 2em;
}

.loadingMaterialMaterialDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;
}

.loadingMaterialStatusDiv {
    width: 28px;
    height: 1.3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadingMaterialLine {
    margin-left: 12px;
    height: 5vh;
    width: 2px;
    background-color: gray;
}

.loadingMaterialLabelPar {
    margin: 0;
    padding: 0;
    margin-left: 25px;
    font-size: clamp(12px,2vw,16px);
}

.loadingMaterialCompletedPar {
    font-size: 3.5vh;
    margin: 0;
    padding: 0;
    margin-left: 2px;
    text-align: center;
    opacity: 0;
    animation: fadeIn 300ms ease-in-out 0s forwards;
    /* animation: fadeIn 800ms; */
}
.loadingMaterialSpinner{
    transform: scale(2);
}

.loadingMaterialStartButtonDiv {
    margin-top: 2em;
    margin-bottom: 2em;
    flex: 1;
}

.loadingMaterialStartButton {
    flex: 1;
    width: 100%;
    opacity: 0;
    --background: #FF9E2C;
    --background-hover: #FF9E2C;
    --background-focused: #FF9E2C;
    --background-activated: #FCD9CA;
    --color: black;
    font-size: large;
    animation: fadeIn 800ms ease-in-out 100ms forwards;
}

@keyframes fadeIn {
    0% { 
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}  

@media only screen and (max-height: 700px) {
    .loadingMaterialMainDiv{
        font-size: 15px;
    }
}
@media only screen and (max-width: 480px) and (min-height:700px) {
    .loadingMaterialMainDiv{
        /* font-size: 18px; */
    }
}

@media (prefers-color-scheme:dark){
    .loadingMaterialCompletedPar{
        color: transparent;  
        text-shadow: 0 0 0 white;
    }
}