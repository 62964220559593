@import url("../../../assets/colorsCSS/colorPalettes.css");

.authenticationCompletionMainGrid {
    max-width: 500px;
    padding: 10px;

    height: 99%;
    display: flex;
    flex-direction: column;
}

.authenticationCompletionLoadingOuterDiv {
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.authenticationCompletionLoadingInnerDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.authenticationCompletionLoadingSpinner {
    margin-right: 20px;
}

.authenticationCompletionInsertEmailTextDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.authenticationCompletionInsertEmailInputDiv {
    flex: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.authenticationCompletionInsertEmailInput {
    text-align: center;

    --padding-start: 15px;
    --padding-end: 15px;

    border: 1px solid gray;
    border-radius: 10px;
    overflow: hidden;
}

.authenticationCompletionConfirmEmailButtonDiv {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.authenticationCompletionConfirmEmailButton {
    text-transform: none;
}