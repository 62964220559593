@import url("../../../assets/colorsCSS/colorPalettes.css");

.settingsGrid {
	background-color: var(--bg-page-color);
	/* margin-left: 90px; */
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: 0.5fr repeat(3, 1fr);
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	padding-inline: 25px;
	margin-right: inherit;
	position: relative;
	overflow: auto;
}

.settingsHeaderDiv {
	grid-area: 1 / 1 / 2 / 3;
}

.fontSection {
	grid-area: 2 / 1 / 3 / 2;
}

.previewSection {
	grid-area: 2 / 2 / 3 / 3;
}

.highlightSection {
	grid-area: 3 / 1 / 4 / 2;
	position: absolute;
	width: 100%;
}

.ttsGrid {
	grid-area: 3 / 2 / 4 / 3;
}

.colorChange {
	grid-area: 4 / 2 / 5 / 3;
}

.settingsMainPage {
	background-color: var(--bg-page-color);
	opacity: 1;
}

.sideMenuSettings {
	width: 90px;
}

.settingsTitleParagraph {
	font-size: 25px;
	font-weight: bold;
	text-align: center;
}

.settingsSectionParagraph {
	font-weight: bold;
	font-size: 18px;
	color: var(--txt-color);
}

.settingsFontNameParagraph {
	text-align: center;
	width: 100%;
}

.fontCard {
	background: var(--bg-content-color);
	border-radius: 10px;
}

.settingsFontSegment {
	width: 80%;
}

#settingsFontSizeInput,
#settingsLineLeadingInput {
	--bar-background-active: var(--callToAction-color);
	--bar-background: var(--neutral-color);
}

.card-content-ios p {
	color: var(--txt-color);
}

.settingsFontArrowButton {
	width: 10%;
	cursor: pointer;
}

.cardWrap {
	background: transparent;
}

.settingsHeaderDiv {
	margin-left: 50px;
}

.settingsHeaderTitle {
	font-size: 2.5vh;
	text-align: center;
}

.settingsHeaderTitleTablet {
	font-size: 50px;
	margin: 0;
	text-align: center;
	color: var(--txt-color);
	font-family: Nunito;
}

.settingsHeaderTablet {
	box-shadow: 0px 0px 20px 1px #0003;
	height: 9vh;
	background: var(--bg-content-color);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.settingsHeaderTabletText {
	height: 6.5vh;
	background: rgb(54, 38, 167);
	display: flex;
	align-items: center;
	/* flex-direction: row-reverse; */
}

.settingsHeader {
	margin-top: 60px;
	margin-bottom: 30px;
	font-size: 50px;
	color: var(--txt-color);
	padding: 0;
}

.settingsDocumentViewingItem::part(native) {
	background: transparent;
}

.settingsHighlightItem {
	border-radius: 29px;
	overflow: hidden;
	border: 1px solid lightgray;
	margin-top: 10px;
	--border-style: 0;
}

.settingsHighlightItemGrid {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.settingsHighlightingMainDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	text-overflow: ellipsis;
}

.sideMenuBtnBurger {
	display: block;
}
.settingsHelpIcon {
	position: absolute;
	top: 15px;
	right: 10px;
	z-index: 1000;
	cursor: pointer;
	color: var(--txt-color);
}
.settingsHighlightPlaceholder {
	font-size: 25px;
	position: absolute;
	opacity: 0.5;
	margin-left: 6px;
	margin-right: 15px;
	z-index: -2;
	max-width: 500px !important;
	text-overflow: ellipsis;
}

.settingsSaveHighlightingButton {
	width: auto;
	min-width: 100px;
	margin: 5px;
}

.settingsSaveHighlightingButton::part(native) {
	background: var(--callToAction-color);
	color: var(--txt-color);
}

.settingsHighlightItemGridRow {
	flex: 1;
}

.settingsHighlightItemGridColorRow {
	flex: 1;
	justify-content: center;
}

.settingsHighlightItemGridRemoveRow {
	flex: 1;
	justify-content: flex-end;
}

.settingsColorDiv {
	width: 20px;
	height: 20px;
	border-radius: 15px;
}

.list-ios {
	background: transparent !important;
}

.settingVoicePreviewButtonIcon {
	margin-right: 10px;
}

.settingsDocumentViewingItemPreviewDiv {
	display: flex;
	flex-direction: column;
}

.settingsFontSizeParagraph {
	font-size: 15px;
	font-weight: bold;
	text-align: center;
}

#lettersToMarkInput {
	width: 85%;
}

/* DA ELIMINARE */

/* DA ELIMINARE */
.modalTitle {
	--background: var(--bg-content-color);
	color: var(--txt-color);
}

.settingsModalLanguageTablet {
	--width: 100%;
	--height: 30vh;
	--border-radius: 30px 30px 0px 0px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.settingsModalLanguagesSelectionTablet {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 85%;
	width: 100%;
	overflow-y: scroll;
	font-family: Nunito !important;
	font-size: 23px;
	background: var(--bg-content-color);
}

.settingsModalLanguagesItemTablet {
	font-size: 18px;
	--inner-border-width: 0px 0px 1.5px;
	max-width: 80vw;
	width: 100%;
	--padding-bottom: 0.9em;
	--padding-start: 0px;
	--border-color: black;
	--background: transparent;
	color: var(--txt-color);
}

.settingsModalDescriptionTablet {
	width: 100%;
	font-size: 21px !important;
	font-family: Nunito !important;
	text-align: center;
}

.settingsModalDescriptionIconTablet {
	font-size: 35px;
	position: absolute;
	top: 2%;
	right: 20px;
}

.settingsModalLanguagesLabelTablet {
	text-align: center;
	font-family: Nunito !important;
}

.settingsDocumentViewingPreviewHeaderParagraph {
	flex: 1;
	font-weight: bold;
	font-size: 18px;
	color: var(--txt-color);
}

.previewBox {
	background: var(--bg-content-color);
	box-shadow: none;
	border-radius: 10px;
	min-height: 4vh;
	padding-inline: 15px;
	margin: 0;
}

.settingsNumericInput {
	text-align: center;
}

.settingsNumericInput > input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.settingsDocumentViewingButtonsDiv {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

.settingsDocumentViewingRangeDiv {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.settingsDocumentViewingRangeButton {
	width: unset;
	color: var(--callToAction-color);
}

.settingsDocumentViewingPreviewParagraph {
	color: var(--txt-color);
	border-radius: 5px;
	padding: 5px;
	overflow: hidden;
	margin-top: 10px;
}

.settingsDocumentViewingPreviewParagraphTablet {
	overflow: hidden;
	margin-top: 20px !important;
}

.settingsEasyReadingAttributionDiv {
	margin-top: 10px;
	display: flex;
	align-items: center;
	color: var(--txt-color);
}

.settingsEasyReadingAttributionParagraph {
	text-align: left;
	margin-left: 10px !important;
}

.settingsEasyReadingAttributionParagraphTablet {
	text-align: left;
	margin-left: 10px !important;
	opacity: 0.37;
}

.settingsHighlightingsToggleDiv {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.settingsColorPickerDiv {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 15px;
}

.flexbox-fix {
	margin-bottom: 10px;
}

.chrome-picker > div {
	border-radius: 15px !important;
}

.settingsHighlightingsToggleParagraph {
	font-weight: bold;
	font-size: 17px;
	margin-left: 10px;
	color: var(--txt-color);
}

.settingsLicensesButtonDiv {
	display: flex;
	justify-content: center;

	margin-top: 20px;
	margin-bottom: 20px;
}

.settingsLicensesButton {
	text-transform: none;
}

.settingsFontDiv {
	display: flex;
	flex: row;
	justify-content: space-between;
	align-items: center;
	width: 70%;
	color: var(--callToAction-color);
}

.settingsDocumentViewingItem {
	width: 100%;
	margin-bottom: 30px;
}

.settingsDocumentViewingItemDiv {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
}

ion-item > .settingsDocumentViewingParagraph {
	font-size: 15px;
	font-weight: bold;
}

.settingsDocumentViewingParagraph {
	left: 20px;
	text-align: left;
	font-size: 15px !important;
	font-weight: bold !important;
	width: 100%;
}

.settingsDocumentViewingItemDivTablet {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.settingsDocumentViewingParagraphTablet {
	position: absolute;
	width: 40%;
	left: 10px;
	text-align: left;
	font-size: 15px !important;
	font-weight: bold !important;
}

.settingsFontContainerList {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

ion-range {
	--bar-height: 15px;
	--bar-border-radius: 15px;
	--knob-size: 40px;
}

.highlightToggle {
	--background-checked: var(--positive-color);
	--background: var(--neutral-color);
	--handle-background-checked: var(--positive-color);
	--track-background-checked: var(--positive-color);
	--track-background: rgba(180, 180, 180, 0.549);
}

.settingsHighlightItem::part(native) {
	background: var(--bg-content-color);
	color: var(--txt-color);
}

.settingsHighlightList {
	background: transparent;
}

.tooltip {
	display: inline-block;
	position: relative;
	text-align: left;
	font-size: 16px;
}

.tooltip .bottom {
	min-width: 200px;
	top: 25px;
	left: 50%;
	transform: translate(-50%, 0);
	padding: 10px 20px;
	color: #444444;
	background-color: #eeeeee;
	font-weight: normal;
	border-radius: 8px;
	font-size: 12px;
	position: fixed;
	z-index: 99999999;
	box-sizing: border-box;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
	display: none;
}

.tooltip:hover .bottom {
	display: block;
}

.tooltip .bottom i {
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: -12px;
	width: 24px;
	height: 12px;
	overflow: hidden;
}

.tooltip .bottom i::after {
	content: "";
	position: absolute;
	width: 12px;
	height: 12px;
	left: 50%;
	transform: translate(-50%, 50%) rotate(45deg);
	background-color: #eeeeee;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
}

.ttsItems::part(native) {
	background: var(--bg-content-color);
	color: var(--txt-color);
}

.ttsList {
	background: transparent !important;
}

.ttsBox {
	background: var(--bg-content-color);
	border-radius: 10px;
	margin: 0;
}

.paletteItems::part(native) {
	background-color: var(--bg-content-color);
	color: var(--txt-color);
	border: none;
	border-radius: 10px;
}
.back-btn-icon-settings {
	font-size: 45px;
	cursor: pointer;
	position: absolute;
	left: 30px;
	top: 30px;
    color:var(--txt-color)
}
.paletteList {
	background: var(--bg-content-color) !important;
	border-radius: 10px;
}

.sc-ion-select-popover-md {
	background: var(--bg-content-color);
}

@media only screen and (max-device-width: 480px),
	only screen and (max-device-width: 540px),
	only screen and (max-width: 480px),
	only screen and (max-width: 540px) {
	.settingsGrid {
		margin-left: 0;
		justify-content: unset;
		display: flex;
		flex-direction: column;
		padding-inline: 0;
		overflow: auto;
	}

	.settingsHeader {
		margin-top: 0;
		margin-bottom: 0;
		text-align: center;
		height: 10vh;
		font-size: 17px;
		background: var(--bg-content-color);
		color: var(--txt-color);
	}

	.settingsHighlightPlaceholder {
		max-width: 200px !important;
	}

	.fontCard {
		background: transparent;
	}

	.settingsDocumentViewingRangeButton {
		width: inherit;
	}

	.settingsDocumentViewingButtonsDiv {
		width: 100%;
	}

	.settingsDocumentViewingItem::part(native) {
		padding: 0;
	}

	ion-range {
		--knob-size: 25px;
	}

	.chrome-picker {
		width: 90% !important;
		border-radius: 15px !important;
		margin-bottom: 15px;
	}

	.settingsDocumentViewingPreviewHeaderParagraph,
	.settingsHighlightingsToggleParagraph {
		padding-left: 20px;
	}

	.settingsHighlightingsToggleParagraph {
		margin: 0;
	}

	.highlightSection {
		position: unset;
	}

	.settingsFontDiv {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}

	.fontSizeIcons {
		color: var(--callToAction-color);
	}

	.settingsFontSizeParagraph {
		display: none;
	}

	.settingsDocumentViewingItem {
		margin-bottom: 15px;
		z-index: -100;
	}

	.settingsDocumentViewingItemDiv {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-bottom: 50px;
		margin-top: 10px;
		justify-content: center;
	}

	.settingsHighlightDiv {
		margin: 15px;
	}

	.settingsHighlightItem {
		border-radius: 15px;
	}

	.settingsHighlightList {
		background: transparent;
		box-shadow: none;
		margin: 15px;
		margin: 0 20px 0 20px;
	}

	.settingsSectionParagraph {
		display: none;
	}

	.settingsDocumentViewingParagraph {
		position: relative;
		width: unset;
		left: unset;
		flex: 1;
		font-weight: bold !important;
	}

	.settingsFontContainerList {
		all: unset;
	}

	.previewBox {
		height: auto;
	}

	.previewBox,
	.ttsBox {
		background: transparent;
		box-shadow: none;
		margin: 0;
	}

	.ttsList,
	.ttsList ion-item::part(native) {
		background: transparent;
	}

	.ttsList {
		background: var(--bg-content-color);
		padding: 20px;
		border-radius: 10px;
	}

	.paletteList {
		margin: 20px;
	}

	.settingsDocumentViewingItemPreviewDiv {
		display: flex;
		flex-direction: column;
		margin-top: 0;
		padding: 0;
		padding-left: 20px;
		padding-right: 20px;
		background: var(--bg-content-color);
		border-radius: 10px;
	}
}

@media only screen and (max-device-width: 820px),
	only screen and (max-device-width: 768px),
	only screen and (max-device-width: 717px),
	only screen and (max-width: 820px),
	only screen and (max-width: 768px),
	only screen and (max-width: 717px) {
	.settingsGrid {
		margin-left: 0;
		justify-content: unset;
		display: flex;
		flex-direction: column;
		padding-inline: 0;
		overflow: auto;
		margin-right: inherit;
	}

	.settingsHeader {
		margin-top: 0;
		margin-bottom: 0;
		text-align: center;
		height: 10vh;
		font-size: 17px;
		background: var(--bg-content-color);
		color: var(--txt-color);
	}

	.settingsHighlightPlaceholder {
		max-width: 200px !important;
	}

	.highlightSection {
		position: unset;
	}

	.fontCard {
		background: transparent;
	}

	.settingsDocumentViewingRangeButton {
		width: inherit;
	}

	.settingsDocumentViewingButtonsDiv {
		width: 100%;
	}

	.settingsDocumentViewingItem::part(native) {
		padding: 0;
	}

	ion-range {
		--knob-size: 25px;
	}

	.chrome-picker {
		width: 90% !important;
		border-radius: 15px !important;
		margin-bottom: 15px;
	}

	.settingsDocumentViewingPreviewHeaderParagraph,
	.settingsHighlightingsToggleParagraph {
		padding-left: 20px;
	}

	.settingsHighlightingsToggleParagraph {
		margin: 0;
	}

	.settingsHighlightingsToggleDiv {
		padding-right: 20px;
	}

	.settingsFontDiv {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}

	.fontSizeIcons {
		color: var(--callToAction-color);
	}

	.settingsFontSizeParagraph {
		display: none;
	}

	.settingsDocumentViewingItem {
		margin-bottom: 15px;
		z-index: -100;
	}

	.settingsDocumentViewingItemDiv {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-bottom: 50px;
		margin-top: 10px;
		justify-content: center;
	}

	.settingsHighlightDiv {
		margin: 15px;
	}

	.settingsHighlightItem {
		border-radius: 15px;
	}

	.settingsHighlightList {
		background: transparent;
		box-shadow: none;
		margin: 15px;
		margin: 0 20px 0 20px;
	}

	.settingsSectionParagraph {
		display: none;
	}

	.settingsDocumentViewingParagraph {
		position: relative;
		width: unset;
		left: unset;
		flex: 1;
		font-weight: bold !important;
	}

	.settingsFontContainerList {
		all: unset;
	}

	.previewBox {
		height: auto;
	}

	.previewBox,
	.ttsBox {
		background: transparent;
		box-shadow: none;
		margin: 0;
	}

	.ttsList,
	.ttsList ion-item::part(native) {
		background: transparent;
	}

	.ttsList {
		background: var(--bg-content-color);
		padding: 20px;
		border-radius: 10px;
	}

	.paletteList {
		margin: 20px;
	}

	.ttsList:nth-child(2) {
		height: 55px;
	}

	.settingsDocumentViewingItemPreviewDiv {
		display: flex;
		flex-direction: column;
		margin-top: 0;
		padding: 0;
		padding-left: 20px;
		padding-right: 20px;
		background: var(--bg-content-color);
		border-radius: 10px;
	}

	.header-ios .homeHeaderToolbar:last-of-type,
	.header-ios .mobileHeaderToolbarHome:last-of-type {
		--border-width: 0;
	}
}

.settingsTtsItemDiv {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.settingsTtsSelect {
	width: 100% !important;
}

.settingsHighlightList {
	background: transparent;
}

.cardBgTablet {
	--background: transparent;
	box-shadow: none;
}

.gridTablet {
	margin: 0 0 0 0;
	overflow: auto;
	/* margin-left: 90px; */
	margin-top: 40px;
	transition: margin-left 0.5s;
}

.headerItemBackToPagePrinc {
	--inner-padding-end: 0px;
	--background: transparent;
	display: flex;
	--color: var(--txt-color);
	--inner-border-width: 0px 0px 0px;
	position: absolute;
	align-items: center;
	height: 60px;
}

.itemSettingsTablet {
	--background: transparent;
	--padding-start: 0px;
	--inner-border-width: 0px 0px 2px 0px;
	color: var(--txt-color);
}

.iconSettingsTablet {
	padding-right: 8px;
	color: var(--txt-color);
}

.settingsModalLanguageTablet .modal-wrapper {
	background: var(--bg-content-color);
}

.settingsTextIonColTablet {
	min-width: 90vw;
	max-width: 90vw;
}

.settingsDocumentViewingPreviewItemTablet {
	margin-bottom: 50px;
	border-radius: 10px;
}

.fontstyle {
	font-family: Nunito !important;
}

@media screen and (orientation: landscape) and (max-height: 1000px) {
	.settingsModalLanguageTablet {
		--height: 45vh;
	}
}

@media screen and (orientation: portrait) and (min-height: 1920px) {
	.settingsModalLanguageTablet {
		--height: 20vh;
	}
}

@media screen and (max-width: 480px) {
	.settingsModalLanguageTablet {
		--height: 55vh;
	}
}

/* COLOR PICKER MODAL*/

.color-preview {
	width: 100%;
	height: 130px;
	margin: 20px auto;
	text-align: center;
	/* Center the hex code */
}

.color-preview-inner {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Style the hex code text */
.color-preview-inner {
	color: #fff;
	/* Text color */
	font-size: 16px;
	/* Adjust the font size as needed */
	font-weight: bold;
	/* Optionally make it bold */
	border-radius: 30px;
	box-shadow: 0px 0px 10px 0px #00000052;
}

.color-box {
	width: 35px;
	height: 35px;
	display: inline-block;
	border-radius: 10px;
	cursor: pointer;
	box-shadow: 0px 0px 10px 0px #00000052;
}

.colorSampleList {
	display: flex;
	justify-content: space-evenly;
	background: transparent;
}

.colorPickerContainer {
	--padding-start: 30px;
	--padding-end: 30px;
	--background: var(--bg-content-color);
}

.colorPickerTitle {
	max-height: 50px;
	color: var(--txt-color);
	text-align: center;
	font-size: 18px;
	font-weight: bold;
	position: unset;
}

.colorPickerSlider {
	--background: transparent;
	--border-width: 0;
}

.saturationRangeSlider,
.brightnessRangeSlider,
.hueRangeSlider {
	--bar-height: 12px;
	--height: 0px;
	--knob-background: #fff;
	--knob-size: 30px;
	margin-top: 10px;
	--bar-background-active: 0;
}

.hueRangeSlider {
	--bar-background: linear-gradient(
		to right,
		hsl(0, 100%, 50%),
		hsl(60, 100%, 50%),
		hsl(120, 100%, 50%),
		hsl(180, 100%, 50%),
		hsl(240, 100%, 50%),
		hsl(300, 100%, 50%),
		hsl(360, 100%, 50%)
	);
}

.saturationRangeSlider {
	--bar-background: linear-gradient(to right, #60afff, #c8e1fa);
}

.brightnessRangeSlider {
	--bar-background: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, /* Transparent at the top */ rgba(0, 0, 0, 1) 100% /* Fully opaque at the bottom */);
}

.colorPickerModal .modal-wrapper {
	border-radius: 10px;
}

.colorPickerText {
	border-bottom: none;
	--background: transparent;
	font-size: 18px;
	padding: 0;
	color: var(--txt-color);
	font-weight: bold;
}

.colorPickerSlidersTxt {
	font-size: 17px;
	color: var(--txt-color);
}

.colorPickerSaveButtonContainer {
	width: 100%;
	display: flex;
	margin-top: 10px;
	justify-content: center;
}

.colorPickerSaveButton {
	--background: #ff331f;
	--border-radius: 100px;
	max-width: 150px;
	text-transform: uppercase;
}

.colorPickerBackIcon {
	display: flex;
	color: var(--txt-color);
	background: var(--bg-content-color);
	padding: 10px 30px 0 30px;
}

.colorPickerOpen {
	height: 20px;
	width: 20px;
	background: conic-gradient(from 0deg, #ff0000, #0a00ff, #00ff1d, #ffeb00, #ff0000);
	border-radius: 100px;
	cursor: pointer;
}

@media screen and (max-width: 869px) {
	.colorPickerModal {
		flex-direction: column;
		--height: 50vh;
		justify-content: flex-end;
	}

	.colorpickerBack {
		height: 35px;
		width: 20px;
		z-index: 1000;
	}
}

@media screen and (max-width: 460px) {
	.colorPickerModal {
		--height: 70vh;
	}
}
