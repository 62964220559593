@import url("../../assets/colorsCSS/colorPalettes.css");


.menuTabBar {
    height: 8vh;
    --background: var(--bg-content-color);
    --border:none;
    box-shadow: 0px -3px 20px 1px #00000030;
}

#tabBarProfile {
    --color: var(--txt-color)
}

.menuTabBarTablet {
    height: 5.3vh;
    display: flex;
    justify-content: space-around;
}

/* Non c'è la verisione tablet perché sono presenti solo in modalita tablet */
.ToolBarLabelTablet {
    margin-left: 0.5vh;
    font-size: 1.5vh;
    font-family: nunito;
}

.menuTabBarButtonTablet {
    --color-selected: #3626a7;
    display: flex;
    flex-direction: row;
}

.menuTabBarButton {
    --color-selected: var(--callToAction-color);
    display: flex;
    flex-direction: row;
}
@media only screen and (min-width: 540px) and (max-width: 850px) {

    .menuTabBar {
        height: 6vh;
    }
}
@media (prefers-color-scheme: dark) {
    :root {
        --bg-content-color: #121212;
        --txt-color: #F4F4FF;
    }

}